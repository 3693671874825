import Checkbox from 'common/formControls/switches/HFCheckbox'
import HFJsonTextArea, { JSON_TYPE_ARRAY } from 'common/formControls/textInputs/HFJsonTextArea'
import Slider from 'common/formControls/sliders/HFSlider'
import { HFDebouncedTextField } from 'common/formControls/textInputs/HFTextField'

const Content = () => {
  const fps = { min: 1, max: 60 }
  const completionTarget = { min: 1, max: 100 }

  return (
    <>
      <HFJsonTextArea
        jsonType={JSON_TYPE_ARRAY}
        name="actions"
        required
      />

      <HFJsonTextArea
        jsonType={JSON_TYPE_ARRAY}
        name="pauses"
        required
      />

      <Slider
        label="Frames Per Second"
        max={fps.max}
        min={fps.min}
        name="fps"
      />

      <Slider
        label="Completion Target"
        max={completionTarget.max}
        min={completionTarget.min}
        name="completionTarget"
      />

      <HFDebouncedTextField
        label="Source URL"
        name="src"
        required
      />

      <Checkbox name="debug" />
    </>
  )
}

export default Content
