import Box from '@mui/material/Box'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import Checkbox from 'common/formControls/switches/HFCheckbox'

const Content = () => (
  <>
    <HFAssetUploader
      label="Audio File"
      name="uploadsMap.audio"
      required
      uploadType="audio"
    />

    <Box
      display="inline"
      mr={3}
    >
      <Checkbox name="minimal" />
    </Box>
  </>
)

export default Content
