import { useCallback, useMemo } from 'react'
import { SCORING_MODE_CHART_EXACT, interactiveVariants } from 'hss/ContentBuilder/consts'
import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_CHART_COMPLETION } from 'core/consts'
import ChartCompletionInteractive from 'hss/sections/contentBlocks/interactives/ChartCompletion'
import withContent from 'hoc/withContent'
import { generateId, pipe } from 'fp/utils'
import { set } from 'fp/objects'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_CHART_COMPLETION
const Interactive = ChartCompletionInteractive

const itemDefaultValues = {
  contentType: 'text',
  value: '',
  isStatic: true,
  isRichInput: false,
}

const defaultValues = {
  groupItemsTogether: false,
  prompt: '',
  'scoring.allOrNothing': false,
  'scoring.maxScore': 0,
  'scoring.mode': SCORING_MODE_CHART_EXACT,
  variant: interactiveVariants.NORMAL,
}

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const ChartCompletion = (formProps) => {
  const thisInstanceDefaultValues = useMemo(
    () => {
      const rowId = generateId()
      const columnId = generateId()
      return ({
        ...defaultValues,
        columns: [{ id: columnId, header: '' }],
        rows: [{ id: rowId }],
        items: [{
          ...itemDefaultValues,
          id: generateId(),
          column: columnId,
          row: rowId,
        }],
      })
    },
    [],
  )

  const handleBeforeSubmit = useCallback(
    data => data.variant && data.variant !== interactiveVariants.NORMAL
      ? pipe(
        // api will fill these out based on the chapter where the interactive gets used
        set('columns', []),
        set('rows', []),
        set('items', []),
      )(data)
      : data,
    [],
  )

  return (
    <Form
      {...{ contentSubType, defaultValues: thisInstanceDefaultValues, formProps, previewButton }}
      captionAllowed
      fullWidth
      name="Interactive-Chart-Completion"
      onBeforeSubmit={handleBeforeSubmit}
      standardsAllowed
      variants={{
        [interactiveVariants.NORMAL]: {
          displayName: 'Normal',
        },
        [interactiveVariants.CHART_COMPLETION_VOCAB_DEFINITIONS]: {
          displayName: 'Vocab: Match Definitions with Terms',
          defaults: {
            prompt: '<p>Complete the chart by matching each definition with the correct term.</p>',
          },
        },
        [interactiveVariants.CHART_COMPLETION_VOCAB_SENTENCES]: {
          displayName: 'Vocab: Complete the Sentences',
          defaults: {
            prompt: '<p>Complete the chart by selecting the term that best completes each sentence.</p>',
          },
        },
      }}
    >
      <Content />
    </Form>
  )
}

const ChartCompletionWithContent = withContent(ChartCompletion, { contentType: CONTENT_TYPE_INTERACTIVE })

export default ChartCompletionWithContent
