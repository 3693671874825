import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { useFormContext, useWatch } from 'react-hook-form'
import HFRadioGroup from 'common/formControls/switches/HFRadioGroup'
import Select from 'common/formControls/selects/HFSelect'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import Headline from 'common/text/Headline'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import { CHANGE_HANDLER_NUMERIC } from 'common/formControls/Form/withHookForm'

const CANVAS_WIDTH_MIN = 300
const CANVAS_HEIGHT_MIN = 300

const scaleUnits = {
  px: 'px',
  mi: 'Miles',
  ft: 'Feet',
  km: 'Kilometers',
  m: 'Meters',
}

const scaleDimensions = [
  { id: 'h', label: 'Horizontal', value: 'horizontal' },
  { id: 'v', label: 'Vertical', value: 'vertical' },
]

const Content = () => {
  const { setValue } = useFormContext()
  const assetCode = useWatch({ name: 'assetCode' })

  const receivedCantoFields = (fields) => {
    Object.entries(fields).forEach(([key, value]) => setValue(
      key,
      value,
      { shouldDirty: true },
    ))
  }

  return (
    <>
      <Box mb={2}>
        <HFAssetUploader
          assetCode={assetCode}
          label="Canvas Background"
          name="uploadsMap.image"
          onCantoComplete={receivedCantoFields}
          uploadType="image"
        />
      </Box>

      <Headline
        mt={4}
        title="Canvas size"
      >
        <HFTextField
          changeHandlerType={CHANGE_HANDLER_NUMERIC}
          inputProps={{ type: 'number' }}
          label="Width"
          name="canvasWidth"
          required
          rules={{
            min: {
              value: CANVAS_WIDTH_MIN,
              message: `Min width is ${CANVAS_WIDTH_MIN}`,
            },
          }}
          sx={{ maxWidth: 150 }}
        />

        <HFTextField
          changeHandlerType={CHANGE_HANDLER_NUMERIC}
          inputProps={{ type: 'number' }}
          label="Height"
          name="canvasHeight"
          required
          rules={{
            min: {
              value: CANVAS_HEIGHT_MIN,
              message: `Min height is ${CANVAS_HEIGHT_MIN}`,
            },
          }}
          sx={{ ml: 2, maxWidth: 150 }}
        />
      </Headline>

      <Headline
        mt={4}
        title="Background Scale"
      >
        <HFRadioGroup
          formLabelProps={{ className: null }}
          label="Background Dimension"
          name="dimension"
          options={scaleDimensions}
        />

        <HFTextField
          changeHandlerType={CHANGE_HANDLER_NUMERIC}
          inputProps={{ min: '0', type: 'number' }}
          label="Distance"
          name="distance"
          sx={{ maxWidth: 150 }}
        />

        <Select
          label="Units"
          name="unit"
          sx={{ maxWidth: 150, ml: 2 }}
        >
          {Object.entries(scaleUnits).map(([unit, label]) => (
            <MenuItem
              key={unit}
              value={unit}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </Headline>
    </>
  )
}

export default Content
