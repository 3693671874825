import { forwardRef, useContext, useRef } from 'react'
import useComponentSize from 'hooks/useComponentSize'
import BaseFormActions from 'common/formControls/Form/FormActions'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import CancelButton from 'common/formControls/buttons/CancelButton'
import SubmitButton from 'common/formControls/buttons/SubmitButton'
import { maxFormWidth } from 'common/formControls/Form/utils'
import useIsFormDirty from 'hooks/useIsFormDirty'

const FormActions = forwardRef((props, previewButtonPortalRef) => {
  const { disabled, forcedDirty } = useContext(additionalContext)

  const isDirty = useIsFormDirty()
  const ref = useRef()
  const { width } = useComponentSize(ref)
  const isSm = width < 350

  return (
    <BaseFormActions
      divider={false}
      {...props}
      maxWidth={maxFormWidth}
      ref={ref}
    >
      <CancelButton
        color="secondary"
        fullWidth={isSm}
        variant="secondary"
        {...{ disabled }}
      />

      {Boolean(previewButtonPortalRef) && <span ref={previewButtonPortalRef} />}

      <SubmitButton
        color="secondary"
        disabled={disabled || !(isDirty || forcedDirty)}
        fullWidth={isSm}
      >
        Save Changes
      </SubmitButton>

    </BaseFormActions>
  )
})

export default FormActions
