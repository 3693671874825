import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_IMAGE_TITLE_DRAG } from 'core/consts'
import ImageTitleDragInteractive from 'hss/sections/contentBlocks/interactives/ImageTitleDrag'
import withContent from 'hoc/withContent'
import { set } from 'fp/objects'
import { maxFormWidth } from 'common/formControls/Form/utils'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_IMAGE_TITLE_DRAG
const Interactive = ImageTitleDragInteractive
const defaultValues = {
  callouts: [],
  imageAltText: '',
  prompt: '',
  uploadsMap: {},
  darkBackdropImage: true,
}
const inputStyle = { maxWidth: maxFormWidth, display: 'block' }

const ImageTitleDrag = (formProps) => {
  const previewButton = props => (
    <PreviewButton
      additionalInteractiveProps={{ words: [] }}
      {...{ Interactive, ...props }}
    />
  )

  return (
    <Form
      disabled
      fullWidth
      inputStyle={inputStyle}
      name="Interactive-ImageTitleDrag"
      standardsAllowed
      {...{
        contentSubType,
        // eslint-disable-next-line react/destructuring-assignment
        defaultValues: set('uploadsMap', formProps?.content?.uploadsMap)(defaultValues),
        formProps,
        previewButton,
      }}
    >

      <Content />

    </Form>
  )
}

const ImageTitleDragWithContent = withContent(ImageTitleDrag, { contentType: CONTENT_TYPE_INTERACTIVE })

export default ImageTitleDragWithContent
