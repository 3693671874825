import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'

const Content = () => (
  <div>
    <HFAssetUploader
      label="Backdrop Watermark"
      name="uploadsMap.image"
      required
      uploadType="image"
    />
  </div>
)

export default Content
