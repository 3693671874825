import { useWatch } from 'react-hook-form'
import { get, set } from 'fp/objects'
import Headline from 'common/text/Headline'
import StringList from 'common/formControls/lists/StringList'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import QuestionPrompt from '../Form/QuestionPrompt'

const { EVALUATION_RANKING_VOCAB_FAMILIARITY } = interactiveVariants
const Content = () => {
  const variant = useWatch({ name: 'variant' })

  return (
    <>
      <QuestionPrompt name="prompt" />

      <HFTextField
        label="Top Label"
        name="firstLabel"
      />

      <HFTextField
        label="Bottom Label"
        name="lastLabel"
      />

      {variant !== EVALUATION_RANKING_VOCAB_FAMILIARITY && (
        <Headline
          hr
          mt={5}
          title="Items"
        >
          <StringList
            allow
            getter={get('label')}
            name="items"
            noLabel
            setter={(item, value) => set('label', value)(item)}
          />
        </Headline>
      )}
    </>
  )
}

export default Content
