import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_SHORT_ANSWER } from 'core/consts'
import ShortAnswerInteractive from 'hss/sections/contentBlocks/interactives/ShortAnswer'
import withContent from 'hoc/withContent'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import QuestionPrompt from '../Form/QuestionPrompt'

const contentSubType = INTERACTIVE_TYPE_SHORT_ANSWER
const Interactive = ShortAnswerInteractive
const defaultValues = {
  question: '',
  sampleAnswer: null,
}

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const ShortAnswer = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-ShortAnswer"
    standardsAllowed
  >

    <QuestionPrompt
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
      name="question"
    />

    <HFRichTextEdit
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
      name="sampleAnswer"
    />

  </Form>
)

const ShortAnswerWithContent = withContent(ShortAnswer, { contentType: CONTENT_TYPE_INTERACTIVE })

export default ShortAnswerWithContent
