import PropTypes from 'prop-types'
import TerritoriesPicker from 'common/formControls/selects/TerritoriesPicker'

const Selections = ({ onChange, value }) => (
  <TerritoriesPicker
    label="Choose one or more territories"
    name="territorySelections"
    onChange={onChange}
    value={value}
  />
)

Selections.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Selections
