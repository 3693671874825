import { forwardRef } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import { set } from 'fp/objects'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import TextField from 'common/formControls/textInputs/TextField'
import { camelCaseToWords } from 'fp/strings'
import Select from 'common/formControls/selects/Select'

const animations = ['wobbleLeft', 'wobbleRight'] // import these?
const ListItemRenderer = forwardRef(({
  allowDeletion,
  disabled,
  index,
  onChange,
  removeItem,
  value,
  item,
}, ref) => {
  const handleChange = fieldName => ({ target }) => {
    onChange(set(
      fieldName,
      target.value,
    )(value))
  }

  return (
    <>
      <Stack py={3}>
        <Box
          component="fieldset"
          flex={1}
        >
          <Box
            component="legend"
            mb={2}
          >
            {`Message ${index + 1}`}
          </Box>
          <TextField
            label="Message"
            name={`message-${index}`}
            onChange={handleChange('message')}
            ref={ref}
            value={item.message}
          />
          <Select
            data-testid="animation-dropdown"
            label="Animation"
            name="defined-animation"
            onChange={handleChange('animation')}
            value={item.animation}
          >

            <MenuItem value="">(none)</MenuItem>
            {animations.map(animation => (
              <MenuItem
                key={animation}
                value={animation}
              >
                {camelCaseToWords(animation).toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Stack>
      {Boolean(allowDeletion) && (
        <IconButton
          aria-label="remove message"
          disabled={disabled}
          onClick={() => removeItem(index)}
        >
          <DeleteIcon />
        </IconButton>

      )}
    </>
  )
})

ListItemRenderer.propTypes = itemRendererProps

export default ListItemRenderer
