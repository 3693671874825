import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_PACING_GUIDE } from 'core/consts'
import PacingGuideInteractive from 'hss/sections/contentBlocks/interactives/PacingGuide'
import withContent from 'hoc/withContent'
import HFJsonTextArea, { JSON_TYPE_OBJECT } from 'common/formControls/textInputs/HFJsonTextArea'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'

const contentSubType = INTERACTIVE_TYPE_PACING_GUIDE
const Interactive = PacingGuideInteractive

const defaultValues = {
  config: '{}',
}

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

// for now it's just a freeform JSON config that can go in here
const PacingGuide = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    interactiveInstructionsAllowed={false}
    name="Interactive-PacingGuide"
    studentInstructionsAllowed={false}
    vocabPhrasesAllowed={false}
  >
    <HFJsonTextArea
      jsonType={JSON_TYPE_OBJECT}
      label="Config"
      name="config"
      required
    />
  </Form>
)

const PacingGuideWithContent = withContent(PacingGuide, { contentType: CONTENT_TYPE_INTERACTIVE })

export default PacingGuideWithContent
