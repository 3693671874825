import { createContext, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { componentShape } from 'core/shapes'
import { BODY_FIELD_NAME } from 'core/consts'

const builderContext = createContext()

const BuilderProvider = ({ children, initialStateForTests }) => {
  const [currentValues, setCurrentValues] = useState()
  const [disabled, setDisabled] = useState()
  const [isValid, setIsValid] = useState(false)
  const [getTrigger, setGetTrigger] = useState()
  const [currentBodyType, setCurrentBodyType] = useState(BODY_FIELD_NAME)
  const ancillaryPortalRef = useRef()

  const value = useMemo(
    () => ({
      ancillaryPortalRef,
      currentBodyType,
      currentValues,
      disabled,
      getTrigger,
      isValid,
      setCurrentBodyType,
      setCurrentValues,
      setDisabled,
      setGetTrigger,
      setIsValid,
      ...initialStateForTests,

    }),
    [
      currentBodyType,
      currentValues,
      disabled,
      getTrigger,
      initialStateForTests,
      isValid,
    ],
  )

  return (
    <builderContext.Provider value={value}>
      {children}
    </builderContext.Provider>
  )
}

BuilderProvider.propTypes = {
  children: componentShape.isRequired,
  initialStateForTests: PropTypes.object,
}

export { builderContext, BuilderProvider }
