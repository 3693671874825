import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_HOTSPOT } from 'core/consts'
import HotspotInteractive from 'hss/sections/contentBlocks/interactives/Hotspot'
import withContent from 'hoc/withContent'
import { maxFormWidth } from 'common/formControls/Form/utils'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_HOTSPOT
const Interactive = HotspotInteractive
const defaultValues = {
  callouts: [],
  imageAltText: '',
  uploadsMap: {},
  darkBackdropImage: true,
}

const additionalInteractiveProps = {
  contentId: 'previewing',
  forceLongTextVisible: true,
}

const inputStyle = { maxWidth: maxFormWidth, display: 'block' }
const previewButton = props => <PreviewButton {...{ additionalInteractiveProps, Interactive, ...props }} />

const Hotspot = formProps => (
  <Form
    captionAllowed
    fullWidth
    inputStyle={inputStyle}
    name="Interactive-HotSpot"
    {...{ contentSubType, defaultValues, formProps, previewButton }}
  >
    <Content />
  </Form>
)
const HotspotWithContent = withContent(Hotspot, { contentType: CONTENT_TYPE_INTERACTIVE })

export default HotspotWithContent
