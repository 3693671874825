import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import Container from '@mui/material/Container'
import { useSelector } from 'react-redux'
import { CONTENT_TYPE_PAGE } from 'core/consts'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import ContentHeadline from 'hss/ContentBuilder/interactives/Form/ContentHeadline'
import { BuilderProvider } from 'hss/ContentBuilder/interactives/Form/builderContext'
import ContainerQuery, { useContainerQuery } from 'common/layout/ContainerQuery'
import { important } from 'styling/theming/base/mixins'
import useNavigation from 'hooks/useNavigation'
import { getQueryParams } from 'selectors/routing'
import { sum } from 'fp/numbers'
import useParentContent from 'hooks/useParentContent'
import { getAnyAncestorIsChapterSummary, getAnyAncestorIsTe } from 'selectors/contentViewer'
import SplitPane from 'common/layout/SplitPane'
import Preview from './Preview'
import EditorContainer from './EditorContainer'
import Editor from './Editor/index.js'
import Tabs from './Tabs'
import LinkToViewer from './LinkToViewer'
import AncillaryPortal from './AncillaryPortal'

const Block = (formProps) => {
  const [containerHeight, setContainerHeight] = useState(0)

  const { up } = useContainerQuery()
  const { location: { pathname, hash: currentTab = '#content' }, navigate } = useNavigation()
  const { type: currentContentType = 'se' } = useSelector(getQueryParams)

  const { content } = formProps
  const parent = useParentContent(content)
  const hasTeAncestor = useSelector(getAnyAncestorIsTe({ content }))
  const hasChapterSummaryAncestor = useSelector(getAnyAncestorIsChapterSummary({ content }))

  const parentIsPage = parent?.contentType === CONTENT_TYPE_PAGE

  const split = up('md')

  const { viewportWidth: width } = useContext(sidebarContext)

  const containerRef = useRef()

  useEffect(() => {
    if (!containerRef.current) return
    const { top: tabsTop } = containerRef.current.getBoundingClientRect()
    setContainerHeight(`calc(100vh - ${tabsTop + (split ? 64 : 30)}px)`)
  }, [split, width])

  const setCurrentTab = useCallback((newValue) => {
    navigate(sum(
      pathname,
      '?type=',
      currentContentType,
      newValue,
    ), { replace: true })
  }, [currentContentType, navigate, pathname])

  const setCurrentContentType = useCallback((newValue) => {
    navigate(sum(
      pathname,
      '?type=',
      newValue,
      currentTab,
    ), { replace: true })
  }, [currentTab, navigate, pathname])

  useEffect(() => {
    if (currentTab === '#preview' && split) {
      setCurrentTab('#content')
    }
  }, [currentTab, setCurrentTab, split])

  const containerProps = useMemo(() => ({
    sx: {
      height: containerHeight,
      maxHeight: containerHeight,
      maxWidth: width,

      '.splitter-layout': {
        maxHeight: containerHeight,
      },

      ...(split ? { paddingRight: important(0) } : null),
    },
  }), [containerHeight, split, width])

  return (
    <BuilderProvider>

      {/* eslint-disable-next-line react/destructuring-assignment */}
      <LinkToViewer content={formProps.content} />

      <ContentHeadline {...formProps} />

      <Container {...containerProps}>

        <TabContext value={(currentTab === '#preview' && split ? '#content' : currentTab) || '#content'}>

          <EditorContainer
            formProps={formProps}
            ref={containerRef}
            style={{ maxWidth: 'unset', height: '100%', position: 'relative' }}
          >

            <Tabs
              contentType={content.contentType}
              currentContentType={currentContentType}
              hasChapterSummaryAncestor={hasChapterSummaryAncestor}
              hasTeAncestor={hasTeAncestor}
              setCurrentContentType={setCurrentContentType}
              setCurrentTab={setCurrentTab}
              split={split}
            />

            <AncillaryPortal />

            {split
              ? (
                <SplitPane
                  primaryMinSize={30}
                  secondaryMinSize={20}
                >
                  <ContainerQuery>
                    <Editor
                      contentType={content.contentType}
                      currentContentType={currentContentType}
                      currentTab={currentTab || '#content'}
                      hasChapterSummaryAncestor={hasChapterSummaryAncestor}
                      hasTeAncestor={hasTeAncestor}
                      parentIsPage={parentIsPage}
                      split
                    />
                  </ContainerQuery>

                  <ContainerQuery>
                    <Preview
                      currentContentType={currentContentType}
                      split
                    />
                  </ContainerQuery>
                </SplitPane>
              )
              : (
                <Editor
                  contentType={content.contentType}
                  currentContentType={currentContentType}
                  currentTab={currentTab || '#content'}
                  hasChapterSummaryAncestor={hasChapterSummaryAncestor}
                  hasTeAncestor={hasTeAncestor}
                  parentIsPage={parentIsPage}
                />
              )}

          </EditorContainer>

        </TabContext>

      </Container>

    </BuilderProvider>
  )
}

export default Block
