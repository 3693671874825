/* eslint-disable import/prefer-default-export */
import { compose } from 'redux'
import { omit, set } from 'fp/objects'

export const transformUIData = data => compose(
  omit(['columns']),
  set('groups', data.columns.map(col => ({
    id: col.id,
    heading: col.heading,
    totalItems: data.showGroupTotals ? col.rows.length : null,
  }))),
  set('items', data.columns.reduce((acc, col) => (
    acc.concat(col.rows.map(x => ({ ...x, groupId: col.id })))
  ), [])),
)(data)
