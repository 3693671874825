import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useWatch } from 'react-hook-form'
import Divider from '@mui/material/Divider'
import useToggleState from 'hooks/useToggleState'
import Headline from 'common/text/Headline'
import * as consts from 'core/consts'
import TerritoriesDialog from './TerritoriesDialog'
import Output from './Output'

const AVAILABLE_TO_CONTENT_TYPES = [
  // consts.CONTENT_TYPE_ASSESSMENT,
  consts.CONTENT_TYPE_BLOCK,
  consts.CONTENT_TYPE_CHAPTER,
  consts.CONTENT_TYPE_COURSE,
  consts.CONTENT_TYPE_ECHO,
  // consts.CONTENT_TYPE_INTERACTIVE,
  consts.CONTENT_TYPE_PAGE,
  consts.CONTENT_TYPE_PAGESET,
  // consts.CONTENT_TYPE_RUBRIC_CRITERIA,
  // consts.CONTENT_TYPE_RUBRIC,
  // consts.CONTENT_TYPE_SCAFFOLD,
  consts.CONTENT_TYPE_SECTION,
  consts.CONTENT_TYPE_SOURCE,
  consts.CONTENT_TYPE_SUBSECTION,
  consts.CONTENT_TYPE_UNIT,
  // consts.CONTENT_TYPE_VOCABULARY,
]

const Territories = () => {
  const [open, toggleOpen] = useToggleState(false)
  const contentType = useWatch({ name: 'contentType' })

  return AVAILABLE_TO_CONTENT_TYPES.includes(contentType)
    ? (
      <Headline
        mt={6}
        title="Territorial Visibility"
      >
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          mb={4}
          mt={2}
          pl={2}
          spacing={2}
        >

          <Output />

          <Button onClick={toggleOpen}>
            Edit
          </Button>

        </Stack>

        <TerritoriesDialog
          open={open}
          toggleOpen={toggleOpen}
        />

        <Divider sx={{ opacity: 0.6 }} />
      </Headline>
    )
    : null
}

export default Territories
