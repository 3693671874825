import { useSelector } from 'react-redux'
import {
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
} from 'hss/ContentBuilder/consts'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { get, set } from 'fp/objects'
import { generateId, matches } from 'fp/utils'
import { getFeaturesForEcho } from 'selectors/contentViewer'
import { prefix, wrap } from 'fp/strings'
import { availableEchoFeatures } from './utils'

const arrayFields = [
  'academicVocabPhraseIds',
  'academicVocabPhrases',
  'applicationStandardIds',
  'applicationStandards',
  'contentVocabPhraseIds',
  'contentVocabPhrases',
  'footnoteVocabPhraseIds',
  'footnoteVocabPhrases',
  'instructionStandardIds',
  'instructionStandards',
]

const deriveDefaultValue = field => arrayFields.includes(field) ? [] : ''

const flattenFeature = ([feature, content]) => [
  ...arrayFields,
  'assetCode',
  ...Object.keys(content.data || {}).map(prefix('data.')),
].reduce((acc, field) => set(
  prefix(`${feature}-`)(field),
  get(field, true)(content) || deriveDefaultValue(field),
  true,
)(acc), {})

const useDefaultFormValues = ({ content: { children } }) => {
  const contentIds = children
    .filter(matches('contentType', CONTENT_TYPE_INTERACTIVE))
    .map(get('id'))

  const features = useSelector(getFeaturesForEcho({ contentIds }))

  const flattenedFeatures = Object
    .entries(features)
    .map(flattenFeature)
    .reduce((acc, feature) => ({ ...acc, ...feature }), {})

  return {
    // act
    [`${ECHO_FEATURE_ACT}-data.bodyText`]: '',

    // blast
    [`${ECHO_FEATURE_BLASTY_BLAST}-data.characterLimit`]: 150,
    [`${ECHO_FEATURE_BLASTY_BLAST}-data.interactiveInstructions`]: '',
    [`${ECHO_FEATURE_BLASTY_BLAST}-data.questionPrompt`]: '',
    [`${ECHO_FEATURE_BLASTY_BLAST}-data.studentInstructions`]: '',

    // picture this
    [`${ECHO_FEATURE_PICTURE_THIS}-data.characterLimit`]: 150,
    [`${ECHO_FEATURE_PICTURE_THIS}-data.studentInstructions`]:
      'Select your image and type your answer directly on it.',

    // poll
    [`${ECHO_FEATURE_POLL}-data.studentInstructions`]: 'Answer the poll question',
    [`${ECHO_FEATURE_POLL}-data.options`]: [
      { id: generateId(), label: '' },
      { id: generateId(), label: '' },
    ],

    // talk back
    [`${ECHO_FEATURE_TALK_BACK}-data.audioLengthLimit`]: 15,

    // enabled states
    ...availableEchoFeatures.reduce((acc, key) => set(
      wrap('features.', '.enabled')(key),
      true,
      true,
    )(acc), {}),

    // general
    responseType: ECHO_FEATURE_TALK_BACK,

    // previously set values
    ...flattenedFeatures,
  }
}

export default useDefaultFormValues
