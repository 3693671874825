import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_AUDIO } from 'core/consts'
import AudioInteractive from 'hss/sections/contentBlocks/interactives/Audio'
import withContent from 'hoc/withContent'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_AUDIO
const Interactive = AudioInteractive
const defaultValues = {
  minimal: false,
  url: '',
}

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const Audio = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    captionAllowed
    floatSupported
    name="Interactive-Audio"
    standardsAllowed
  >
    <Content />
  </Form>
)

const AudioWithContent = withContent(Audio, { contentType: CONTENT_TYPE_INTERACTIVE })

export default AudioWithContent
