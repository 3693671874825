import { forwardRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import TextField from 'common/formControls/textInputs/TextField'

const ListItemRenderer = forwardRef(({
  allowDeletion,
  disabled,
  index,
  onChange,
  removeItem,
  ...rest
}, ref) => {
  const { setValue } = useFormContext()
  const descriptionField = `${rest.name}.${index}.description`
  const [score, description] = useWatch({ name: [
    `${rest.name}.${index}.score`,
    descriptionField,
  ] })

  const handleChange = ({ target }) => {
    setValue(descriptionField, target.value)
  }

  return (
    <Stack
      direction="row"
      style={{ width: '100%' }}
    >
      <Box
        alignItems="center"
        bgcolor="grey.2"
        color="white"
        display="flex"
        fontSize="18px"
        height={47}
        justifyContent="center"
        width={48}
      >
        {score}
      </Box>
      <TextField
        data-testid={`option-input-${index}`}
        margin="dense"
        name={descriptionField}
        onChange={handleChange}
        ref={ref}
        value={description || ''}
      />
    </Stack>
  )
})

ListItemRenderer.propTypes = itemRendererProps

export default ListItemRenderer
