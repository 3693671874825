import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import HFSwitch from 'common/formControls/switches/HFSwitch'

const AllowMultipleSelectionSwitch = ({ numberCorrect }) => {
  const { setValue } = useFormContext()

  useEffect(() => {
    if (numberCorrect > 1) {
      setValue('allowMultipleSelection', true)
    }
  }, [numberCorrect, setValue])

  return (
    <HFSwitch
      disabled={numberCorrect > 1}
      name="allowMultipleSelection"
    />
  )
}

AllowMultipleSelectionSwitch.propTypes = {
  numberCorrect: PropTypes.number.isRequired,
}

export default AllowMultipleSelectionSwitch
