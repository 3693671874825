import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import CollapsibleSection, { StylerForSingleField } from 'common/layout/CollapsibleSection'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import { isEmptyString } from 'fp/strings'

const InteractiveInstructions = ({
  className,
  name = 'interactiveInstructions',
}) => {
  const interactiveInstructions = useWatch({ name })

  return (
    <CollapsibleSection
      className={className}
      empty={isEmptyString(interactiveInstructions)}
      label="Interactive Instructions"
    >
      <HFTextField
        aria-label="interactive instructions"
        label=""
        maxRows={4}
        multiline
        name={name}
        variant="filled"
      />
    </CollapsibleSection>
  )
}

InteractiveInstructions.propTypes = {
  name: PropTypes.string,
}

const StyledInteractiveInstructions = StylerForSingleField(InteractiveInstructions)

export default StyledInteractiveInstructions
