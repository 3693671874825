import { useWatch } from 'react-hook-form'
import { SCORING_MODES } from 'hss/ContentBuilder/consts'
import Well from 'common/layout/Well'
import ScoringMode from './ScoringMode'
import MaxScore from './MaxScore'
import AllOrNothing from './AllOrNothing'

const Scoring = () => {
  const contentSubType = useWatch({ name: 'contentSubType' })

  return SCORING_MODES[contentSubType]
    ? (
      <Well>

        <ScoringMode />

        <MaxScore />

        <AllOrNothing />

      </Well>
    )
    : null
}

export default Scoring
