import Typography from '@mui/material/Typography'
import { useWatch } from 'react-hook-form'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Accordion from '@mui/material/Accordion'
import { useEffect, useRef } from 'react'
import {
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TALK_BACK,
  ECHO_FEATURE_TOP_TEN,
} from 'hss/ContentBuilder/consts'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import { suffix } from 'fp/strings'
import HFAssetCodeField from 'common/formControls/textInputs/HFAssetCodeField'
import Standards from 'common/standards/Standards'
import Vocabulary from 'common/vocabulary/Vocabulary'
import useHasErrors from 'common/formControls/Form/FormIsland/useHasErrors'
import { when } from 'fp/utils'
import FeatureNoop from './FeatureNoop'
import FeaturePoll from './FeaturePoll'
import {
  featurePaneShape,
  featuresWithAssetCodes,
  featuresWithStandards,
  featuresWithVocabulary,
} from './utils'
import FeatureAct from './FeatureAct'
import FeatureBlast from './FeatureBlast'
import FeatureTalkBack from './FeatureTalkBack'
import FeaturePictureThis from './FeaturePictureThis'

const responseFeatures = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
]

const alwaysOnFeatures = [
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TOP_TEN,
]

const FeaturePanes = {
  [ECHO_FEATURE_ACT]: FeatureAct,
  [ECHO_FEATURE_BLASTY_BLAST]: FeatureBlast,
  [ECHO_FEATURE_PICTURE_THIS]: FeaturePictureThis,
  [ECHO_FEATURE_POLL]: FeaturePoll,
  [ECHO_FEATURE_RATE]: FeatureNoop,
  [ECHO_FEATURE_TALK_BACK]: FeatureTalkBack,
  [ECHO_FEATURE_TOP_TEN]: FeatureNoop,
}

const Feature = (props) => {
  const { expanded, feature, label, name, onExpandSection } = props
  const Pane = FeaturePanes[feature]
  const alwaysOn = alwaysOnFeatures.includes(feature)
  const response = responseFeatures.includes(feature)
  const ref = useRef()
  const enabled = useWatch({ name: suffix('.enabled')(name) })
  const excludeContentId = useWatch({ name: `${feature}-assetCode` })
  const hasErrors = useHasErrors()

  useEffect(() => {
    when(hasErrors, onExpandSection, feature)
    if (hasErrors)ref.current.focus()
  }, [feature, hasErrors, onExpandSection])

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onChange={() => onExpandSection(expanded ? '' : feature)}
      ref={ref}
      variant="form-section-dense"
    >

      <AccordionSummary variant="form-section-dense">
        {alwaysOn || response
          ? (
            <Typography
              ml={7}
              variant="toc-title"
            >
              {label}
            </Typography>
          )
          : (
            <div>
              {/* this div prevents the expansion button from going full width */}
              <HFSwitch
                label={label}
                name={`${name}.enabled`}
                onClick={e => e.stopPropagation()}
              />
            </div>
          )}
      </AccordionSummary>

      <AccordionDetails variant="form-section-dense">

        {!!featuresWithAssetCodes.includes(feature) && (
          <HFAssetCodeField
            disabled={!enabled}
            excludeContentId={excludeContentId}
            name={`${feature}-assetCode`}
            required={false}
            style={{ marginBottom: 40, marginTop: 20 }}
          />
        )}

        <Pane
          {...props}
          disabled={!enabled}
        />

        {!!featuresWithStandards.includes(feature) && (
          <Standards
            disabled={!enabled}
            namePrefix={`${feature}-`}
            variant="collapsed-heading"
          />
        )}

        {!!featuresWithVocabulary.includes(feature) && (
          <Vocabulary
            disabled={!enabled}
            namePrefix={`${feature}-`}
            variant="collapsed-heading"
          />
        )}

      </AccordionDetails>

    </Accordion>
  )
}

Feature.propTypes = featurePaneShape

export default Feature
