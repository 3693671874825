import Headline from 'common/text/Headline'
import {
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SCAFFOLD,
} from 'core/consts'
import withContent from 'hoc/withContent'
import actionTypes from 'reducers/actionTypes'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import ChildListSection from '../ChildListSection'
import CurriculumForm from '../CurriculumForm'
import ImageControls from '../ImageControls'
import Features from './Features'
import ResponseType from './ResponseType'
import useDefaultFormValues from './useDefaultFormValues'

const Echo = (formProps) => {
  const defaultFormValues = useDefaultFormValues(formProps)

  return (
    <CurriculumForm
      actionType={actionTypes.CONTENT_ECHO_SAVE}
      assetCodeRequired
      defaultValues={defaultFormValues}
      formProps={formProps}
      tagsAllowed
    >

      <ImageControls
        formProps={formProps}
        label="Hero Image"
        showMetaData
        thumb={false}
      />

      <HFAssetUploader
        label="Thumbnail"
        name="uploadsMap.imageThumb"
        uploadType="image"
        uploadVariant="thumb"
      />

      <ChildListSection
        contentTypes={[CONTENT_TYPE_BLOCK, CONTENT_TYPE_SCAFFOLD]}
        formProps={formProps}
        name="children"
        title="Content Blocks"
      />

      <ResponseType />

      <Headline
        gutterBottom
        mt={3}
        pb={1}
        title="Features / Tabs"
      >

        <Features />

      </Headline>

    </CurriculumForm>
  )
}

const EchoWithContent = withContent(Echo, {
  contentType: CONTENT_TYPE_ECHO,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default EchoWithContent
