import { AdvancedListPrime } from 'common/formControls/lists/AdvancedList'
import { identity, takeSecond } from 'fp/utils'
import ListItemRenderer from './ListItemRenderer'

// TODO: Put sorting back in
/*
  If have 6 ratings, and you change the maxScore to 3, then to 8,
  then drag the ratings around, you will see that AdvancedList
  is using stale values and descriptions disappear.
  If you change another form value, like startAtZero,
  before dragging items around, then AdvancedList gets updated values.
*/
const Options = props => (
  <AdvancedListPrime
    allowReordering={false}
    getter={identity}
    ItemRenderer={ListItemRenderer}
    setter={takeSecond}
    {...props}
  />
)

export default Options
