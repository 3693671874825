import { listShape } from 'common/formControls/lists/BasicList'
import withList from 'common/formControls/lists/withList'
import Headline from 'common/text/Headline'

const Callouts = ({ ListRenderer, ItemRenderer, ...rest }) => (
  <Headline
    mt={4}
    title="Ratings"
  >
    <ListRenderer {...rest} />
  </Headline>
)

Callouts.propTypes = listShape

const WiredCallouts = withList(Callouts)

export default WiredCallouts
