import PropTypes from 'prop-types'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCallback, useEffect, useState } from 'react'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import { when } from 'fp/utils'

const LimitSelectionsSwitch = ({ numberCorrect }) => {
  const [allowMultipleSelection, limitSelectionsTo] = useWatch({ name: ['allowMultipleSelection', 'limitSelectionsTo'] })
  const [limitSelections, setLimitSelections] = useState()
  const [userChanged, setUserChanged] = useState(false)
  const { setValue } = useFormContext()

  const handleChange = useCallback(() => {
    setUserChanged(true)
    setLimitSelections(!limitSelections)
  }, [limitSelections])

  useEffect(() => {
    when(!allowMultipleSelection, setLimitSelections, false)
  }, [allowMultipleSelection, setValue])

  useEffect(() => {
    if (!userChanged && limitSelectionsTo && !limitSelections) {
      setLimitSelections(true)
    }
  }, [limitSelections, limitSelectionsTo, userChanged])

  useEffect(() => {
    if (limitSelections) {
      setValue('limitSelectionsTo', numberCorrect, { shouldDirty: userChanged })
    } else {
      setValue('limitSelectionsTo', null, { shouldDirty: userChanged })
    }
  }, [limitSelections, numberCorrect, setValue, userChanged])

  return (
    <SwitchFormControl
      checked={limitSelections}
      disabled={!allowMultipleSelection}
      helperText="Limit the number of selections to the number of correct answers"
      label="Limit Selections"
      onChange={handleChange}
    />
  )
}

LimitSelectionsSwitch.propTypes = {
  numberCorrect: PropTypes.number.isRequired,
}

export default LimitSelectionsSwitch
