import PropTypes from 'prop-types'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import MuiFormHelperText from '@mui/material/FormHelperText'
import withProps from 'hoc/withProps'
import { whenPresent } from 'fp/utils'
import { toInt } from 'fp/strings'
import { rem } from 'styling/theming/base/mixins'

const FormHelperText = withProps(MuiFormHelperText, { sx: { ml: 5 } })

const Fieldset = withProps(FormControl, { component: 'fieldset',
  sx: {
    border: '1px solid',
    borderColor: 'grey.0',
    borderRadius: 0,
    padding: rem(0.5, 2, 2),
    marginTop: 3,
    legend: {
      padding: rem(0.5),
    },
  } })

const ScaffoldAssociationPicker = ({ onChange, value = false, ...rest }) => {
  const handleChange = (_, newValue) => {
    whenPresent(onChange, Boolean(toInt(newValue)))
  }

  return (
    <Fieldset {...rest}>
      <legend>Content Association</legend>

      <RadioGroup
        name="standalone"
        onChange={handleChange}
        value={value ? 1 : 0}
      >
        <FormControlLabel
          control={<Radio />}
          label="Attached to Interactive"
          value={0}
        />
        <FormHelperText>
          Scaffold will attach itself to the first applicable interactive which <b>precedes</b> it
        </FormHelperText>

        <FormControlLabel
          control={<Radio />}
          label="Standalone"
          sx={{ mt: 1 }}
          value={1}
        />
        <FormHelperText>
          Scaffold will not be directly associated with any content
        </FormHelperText>
      </RadioGroup>
    </Fieldset>
  )
}

ScaffoldAssociationPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
}

export default ScaffoldAssociationPicker
