import { useWatch } from 'react-hook-form'
import { compose } from 'redux'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as consts from 'core/consts'
import { imgSrc } from 'common/formControls/selects/TerritoriesPicker'
import { findObj } from 'fp/arrays'
import { get } from 'fp/objects'
import { curry } from 'fp/utils'
import { callWith } from 'fp/call'

const labels = {
  [consts.TERRITORY_SELECTION_TYPE_EVERYONE]: 'Visible to ALL',
  [consts.TERRITORY_SELECTION_TYPE_INCLUDE]: 'ONLY visible to:',
  [consts.TERRITORY_SELECTION_TYPE_EXCLUDE]: 'EXCLUDED from:',
}

export const stateName = compose(
  get('name'),
  callWith(consts.TERRITORIES),
  curry(findObj, 2, 'id'),
)

const Output = () => {
  const [territorySelections, territorySelectionType] = useWatch({ name: ['territorySelections', 'territorySelectionType'] })

  return (
    <div>
      <Typography component="div">
        {labels[territorySelectionType]}
      </Typography>

      {territorySelectionType !== consts.TERRITORY_SELECTION_TYPE_EVERYONE && (
        <Box
          display="flex"
          gap={1}
          mt={1}
        >
          {territorySelections
            .sort()
            .map(id => (
              <Chip
                avatar={(
                  <Avatar
                    alt={stateName(id)}
                    src={imgSrc({ id })}
                    variant="shadow-1"
                  />
                )}
                key={id}
                label={stateName(id)}
                variant="outlined"
              />
            ))}
        </Box>
      )}
    </div>
  )
}

export default Output
