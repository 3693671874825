import PropTypes from 'prop-types'
import { useFormContext, useWatch } from 'react-hook-form'
import Button from '@mui/material/Button'
import { useMemo } from 'react'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import { titleCase } from 'fp/strings'
import { isEmpty } from 'fp/arrays'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'

const MediaList = (props) => {
  const { uploadType } = props
  const { setValue } = useFormContext()
  const label = titleCase(uploadType)
  const uploadsMap = useWatch({ name: 'uploadsMap' })
  const uploads = useMemo(
    () => Object.keys(uploadsMap).filter(key => key.includes(uploadType)),
    [uploadType, uploadsMap],
  )
  const uploaders = isEmpty(uploads) ? [`${uploadType}0`] : uploads

  const handleAdd = () => {
    setValue(`uploadsMap.${uploadType}${uploaders.length}`, null)
  }

  useDeepCompareEffect(() => {
    const nonEmptyMedia = uploads.filter(upload => uploadsMap[upload] !== null)
    const renamed = nonEmptyMedia.reduce((keyMap, key, idx) => ({
      ...keyMap,
      [`${uploadType}${idx}`]: uploadsMap[key],
      ...(`${uploadType}${idx}` === key ? {} : { [key]: null }),
    }), {})
    Object.keys(renamed).forEach((key) => { setValue(`uploadsMap.${key}`, renamed[key]) })
  }, [setValue, uploadType, uploadsMap])
  return (
    <>

      {uploaders.map((key, idx) => (
        <HFAssetUploader
          key={key}
          label={`${label} ${idx + 1}`}
          name={`uploadsMap.${key}`}
          uploadType={uploadType}
        />
      ))}
      <Button
        disabled={uploaders.length > 9}
        onClick={handleAdd}
        size="small"
        sx={{ mt: 2 }}
      >
        Add
      </Button>
    </>
  )
}

MediaList.propTypes = {
  uploadType: PropTypes.string.isRequired,
}

export default MediaList
