import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_ACTIVITY } from 'core/consts'
import ActivityInteractive from 'hss/sections/contentBlocks/interactives/Activity'
import withContent from 'hoc/withContent'
import { SCORING_MODE_RUBRIC } from '../consts'
import Form from './Form'
import PreviewButton from './Form/Preview/PreviewButton'

const contentSubType = INTERACTIVE_TYPE_ACTIVITY
const Interactive = ActivityInteractive
const defaultValues = { scoring: { mode: SCORING_MODE_RUBRIC } }

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const Activity = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-Activity"
    standardsAllowed
  />
)
// no other controls needed -- Form provides the rubric selector

const ActivityWithContent = withContent(Activity, { contentType: CONTENT_TYPE_INTERACTIVE })

export default ActivityWithContent
