import { styled } from '@mui/material/styles'

const Details = styled(
  'details',
  { name: 'ChapterSummary-details' },
)(({ theme: { mixins: { rem }, palette } }) => ({
  borderRadius: 10,
  borderColor: palette.border[1],
  background: palette.border[2],
  margin: rem(3, 0, 2),
}))

const Info = () => (
  <Details>
    <summary>About chapter summaries...</summary>
    <ol>
      <li>
        There can be only one instance of this special type of `subsection` per
        chapter
      </li>
      <li>
        For students, these appear as the last subsection of the first section
        which is typically the <i>"Chapter Overview"</i> section
      </li>
      <li>
        They appear as the second tab of the first TE section which is typically
        the <i>"Chapter Preview"</i> section
      </li>
      <li>
        Chapter summaries are not visible while browsing the library
      </li>
      <li>
        They can be turned off for students during assignment creation
      </li>
      <li>
        Chapter summaries are restricted from having scorable interactives or content
      </li>
    </ol>
  </Details>
)

export default Info
