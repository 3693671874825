import HFSwitch from 'common/formControls/switches/HFSwitch'

const Frameless = () => (
  <HFSwitch
    helperText="Display this interactive by itself, with no header information"
    name="frameless"
  />
)

export default Frameless
