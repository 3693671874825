import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import TabPanel from '@mui/lab/TabPanel'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import FormActions from 'hss/ContentBuilder/interactives/Form/FormActions'
import DebugViewInternalContent from 'common/formControls/textInputs/RichTextEdit/utils/DebugViewInternalContent'
import { LEVELED_TEXT_BODY_FIELD_NAME, SPANISH_BODY_FIELD_NAME, TEACHER_EDITION_PREFIX, TOGGLE_STATE_BLOCK_EDITOR_DEBUG_VISIBLE } from 'core/consts'
import useToggleState from 'hooks/useToggleState'
import { isDevEnv, isPreviewEnv } from 'selectors/index'
import { contentTypeShape } from 'core/shapes'
import Preview from '../Preview'
import AttributesPanel from './AttributesPanel'
import ContentPanel from './ContentPanel'

const formFields = {
  es: SPANISH_BODY_FIELD_NAME,
  lv: LEVELED_TEXT_BODY_FIELD_NAME,
  se: 'body',
  te: `${TEACHER_EDITION_PREFIX}body`,
}

const fieldsToWatch = Object
  .values(formFields)
  .map(name => ({ name }))

const Panel = styled(
  TabPanel,
  { name: 'Editor-index' },
)(({ theme: { mixins: { important, rem } } }) => ({
  padding: rem(2.5, 0, 0),
  '.DraftEditor-root': {
    minHeight: important('unset'),
  },
}))

const Editor = forwardRef((props, ref) => {
  const {
    contentType,
    currentContentType,
    currentTab,
    hasChapterSummaryAncestor,
    hasTeAncestor,
    parentIsPage,
    split = false,
  } = props

  const [debugVisible, toggleDebugVisible] = useToggleState(false, TOGGLE_STATE_BLOCK_EDITOR_DEBUG_VISIBLE)

  const isDev = useSelector(isDevEnv)
  const isStaging = useSelector(isPreviewEnv)

  return (
    <div
      ref={ref}
      style={split ? { paddingRight: 40, marginLeft: 1 } : {}}
    >

      <Panel value="#content">
        <ContentPanel
          currentContentType={currentContentType}
          debugVisible={debugVisible}
          hasChapterSummaryAncestor={hasChapterSummaryAncestor}
          hasTeAncestor={hasTeAncestor}
          parentIsPage={parentIsPage}
          toggleDebugVisible={toggleDebugVisible}
        />
      </Panel>

      <Panel value="#attributes">
        <AttributesPanel
          contentType={contentType}
          currentContentType={currentContentType}
        />
      </Panel>

      <Panel value="#preview">
        <Preview currentContentType={currentContentType} />
      </Panel>

      {currentTab !== '#preview' && <FormActions divider={!split && currentTab !== '#content'} />}

      {Boolean((isDev || isStaging) && debugVisible) && (
        <DebugViewInternalContent
          fieldsToWatch={fieldsToWatch}
          formField={formFields[currentContentType]}
        />
      )}
    </div>
  )
})

Editor.propTypes = {
  contentType: contentTypeShape.isRequired,
  currentTab: PropTypes.oneOf(['#attributes', '#content', '#preview']).isRequired,
  currentContentType: PropTypes.oneOf(['se', 'te']).isRequired,
  hasChapterSummaryAncestor: PropTypes.bool.isRequired,
  hasTeAncestor: PropTypes.bool.isRequired,
  parentIsPage: PropTypes.bool.isRequired,
  split: PropTypes.bool,
}

export default Editor
