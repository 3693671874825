import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_VIDEO } from 'core/consts'
import VideoInteractive from 'hss/sections/contentBlocks/interactives/Video'
import withContent from 'hoc/withContent'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_VIDEO
const Interactive = VideoInteractive
const defaultValues = {
  allowAnnotations: true,
  comments: [],
  sceneDescription: '',
  uploadsMap: {},
}

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const Video = formProps => (
  <Form
    {...{
      captionOnSideAllowed: false,
      contentSubType,
      defaultValues,
      formProps,
      previewButton,
    }}
    captionAllowed
    floatSupported
    name="Interactive-Video"
    standardsAllowed
  >
    <Content />
  </Form>
)

const VideoWithContent = withContent(Video, { contentType: CONTENT_TYPE_INTERACTIVE })

export default VideoWithContent
