import EchoRichTextEditor from './EchoRichTextEditor'
import { featurePaneShape } from './utils'

const FeatureAct = ({ disabled, feature }) => (
  <EchoRichTextEditor
    disabled={disabled}
    label="Body Text"
    name={`${feature}-data.bodyText`}
    required={!disabled}
  />
)

FeatureAct.propTypes = featurePaneShape

export default FeatureAct
