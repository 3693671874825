import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import Tooltip from '@mui/material/Tooltip'
import { useFieldArray } from 'react-hook-form'
import { compose } from 'redux'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useEffect, useRef, useState } from 'react'
import { filter } from 'fp/arrays'
import { generateId, isDefined, matches, not } from 'fp/utils'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import { componentShape } from 'core/shapes'
import ResponsiveTable from 'common/layout/ResponsiveTable'
import useToggleState from 'hooks/useToggleState'
import Cell from './Cell'

const Table = ({
  allowColumnEdits = false,
  allowRowEdits = false,
  newItemProps = { contentType: 'text',
    isStatic: true,
    value: '',
    isRichInput: false },
  CellRenderer = Cell,
}) => {
  const ref = useRef()
  const addRowButtonRef = useRef()
  const { append: appendColumn, fields: columns, remove: removeColumn } = useFieldArray({ name: 'columns', keyName: 'key' })
  const { append: appendRow, fields: rows, remove: removeRow } = useFieldArray({ name: 'rows', keyName: 'key' })
  const { append: appendItem, fields: items, replace: replaceItems } = useFieldArray({ name: 'items', keyName: 'key' })
  const [removedRow, setRemovedRow] = useState()
  const [addedRow, toggleAddedRow] = useToggleState()

  const handleAddRow = () => {
    const newRowId = generateId()
    toggleAddedRow()
    appendRow({ id: newRowId })
    appendItem(columns.map(({ id: columnId }) => ({
      ...newItemProps,
      id: generateId(),
      column: columnId,
      row: newRowId,
    })))
  }

  const handleRemoveRow = (idx) => {
    const { id: rowId } = rows[idx]
    setRemovedRow(idx)
    compose(
      replaceItems,
      filter(compose(not, matches('row', rowId))),
    )(items)
    removeRow(idx)
  }

  const handleAddColumn = () => {
    const newColumnId = generateId()
    appendColumn({
      id: newColumnId,
      header: '',
    })
    appendItem(rows.map(({ id: rowId }) => ({ ...newItemProps, id: generateId(), column: newColumnId, row: rowId })))
  }

  const handleRemoveColumn = (idx) => {
    const { id: columnId } = columns[idx]
    compose(
      replaceItems,
      filter(compose(not, matches('column', columnId))),
    )(items)
    removeColumn(idx)
  }

  useEffect(() => {
    if (isDefined(removedRow)) {
      if (removedRow === rows.length || rows.length === 1) {
        addRowButtonRef.current?.focus()
      } else {
        const selector = removedRow === 0 ? 'first-child' : `nth-child(${removedRow + 1})`
        ref.current.querySelector(`tr:${selector} button`)?.focus()
      }
      setRemovedRow(null)
    }
  }, [removedRow, rows])

  useEffect(() => {
    if (addedRow) {
      ref.current.querySelector(`tr:nth-child(${rows.length}) textarea,
      tr:nth-child(${rows.length}) input[type="text"] `)?.focus()
      toggleAddedRow()
    }
  }, [addedRow, rows, toggleAddedRow])

  return (
    <Stack
      gap={2}
      my={8}
    >
      {Boolean(allowColumnEdits) && (
        <Button
          onClick={handleAddColumn}
          size="small"
          style={{ alignSelf: 'flex-start' }}
        >
          Add Column
        </Button>
      )}

      <ResponsiveTable>
        <thead>
          <tr>
            {columns.map(({ id }, idx) => (
              <th key={id}>
                {Boolean(columns.length > 1 && allowColumnEdits) && (
                  <Tooltip title={`Remove Column ${idx + 1}`}>
                    <IconButton
                      data-testid={`remove-col-btn-${idx}`}
                      onClick={() => handleRemoveColumn(idx)}
                    >
                      <RemoveCircle />
                    </IconButton>
                  </Tooltip>
                )}

                {allowColumnEdits
                  ? (
                    <HFTextField
                      label={`Column ${idx + 1} Header`}
                      name={`columns.${idx}.header`}
                      required
                    />
                  )
                  : columns[idx].header}
              </th>
            ))}
            {Boolean(allowRowEdits) && (
              <th
                aria-label="Remove Row"
                style={{ width: 10 }}
              />
            )}
          </tr>
        </thead>

        <tbody ref={ref}>
          {rows.map(({ id: rowId }, rowIdx) => (
            <tr key={rowId}>
              {columns.map(({ header, id: columnId }) => (
                <CellRenderer
                  columnHeader={header}
                  columnId={columnId}
                  itemIdx={items.findIndex(item => item.column === columnId && item.row === rowId)}
                  key={`${rowId}-${columnId}`}
                  rowIdx={rowIdx}
                />
              ))}
              {Boolean(allowRowEdits) && (
                <td className="remove">
                  {rows.length > 1 && (
                    <Tooltip title={`Remove Row ${rowIdx + 1}`}>
                      <IconButton onClick={() => handleRemoveRow(rowIdx)}>
                        <RemoveCircle />
                      </IconButton>
                    </Tooltip>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </ResponsiveTable>
      {Boolean(allowRowEdits) && (
        <Button
          onClick={handleAddRow}
          ref={addRowButtonRef}
          size="small"
          style={{ marginTop: 20, alignSelf: 'flex-start' }}
        >
          Add Row
        </Button>
      )}
    </Stack>
  )
}

Table.propTypes = {
  allowColumnEdits: PropTypes.bool,
  allowRowEdits: PropTypes.bool,
  CellRenderer: componentShape,
  newItemProps: PropTypes.object,
}

export default Table
