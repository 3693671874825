import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { useFormContext, useWatch } from 'react-hook-form'
import { produce } from 'immer'
import { useEffect } from 'react'
import { generateId } from 'fp/utils'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import { arraySequence } from 'fp/arrays'
import HFSelect from 'common/formControls/selects/HFSelect'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import Criteria from './Criteria'
import Callouts from './Criteria/Callouts'

const Content = () => {
  const { clearErrors, setError, setValue } = useFormContext()
  const [
    children,
    maxScore,
    startAtZero,
  ] = useWatch({ name: ['children', 'maxScore', 'startAtZero'] })

  useEffect(() => {
    clearErrors()
    children.forEach((c, idx) => {
      if (!c.data.title) {
        setError(`children.${idx}.data.title`, { type: 'custom', message: 'Your rating needs a name.' })
      } else if (c.data.options.find(o => !o.description)) {
        setError(`children.${idx}.data.title`, { type: 'custom', message: 'You have too few rating descriptions. Ensure each score for this rating has a description.' })
      }
    })
  }, [children, clearErrors, setError])

  const maxScores = arraySequence(9).map(i => i + 2)

  useEffect(() => {
    children.map((c, idx) => produce(c, (draft) => {
      const newNumberOfOptions = maxScore + (startAtZero ? 1 : 0)
      if (draft.data.options.length < newNumberOfOptions) {
        const diff = newNumberOfOptions - draft.data.options.length
        draft.data.options.push(...arraySequence(diff).map(() => ({ id: generateId(), description: '' })))
      } else {
        draft.data.options.splice(newNumberOfOptions)
      }
      draft.data.options = draft.data.options.map((o, idx2) => ({
        ...o,
        score: idx2 + (startAtZero ? 0 : 1),
      }))
      setValue(`children.${idx}.data.options`, draft.data.options)
    }))
    // TODO: Figure out why updating the children was not always updating AdvancedList
    // specifically when making maxScore smaller than it was previously
    // setValue('children', newChildren)
    // ESLINT: removing children from the dependency array since this method updates children
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxScore, setValue, startAtZero])

  return (
    <>
      <HFRichTextEdit
        name="description"
        required
      />
      <Stack
        direction="row"
        mt={4}
      >
        <HFSelect
          name="maxScore"
          style={{ width: '110px', marginRight: '4rem' }}
        >
          {maxScores.map(i => (
            <MenuItem
              key={i}
              value={i}
            >
              {i}
            </MenuItem>
          ))}
        </HFSelect>
        <HFSwitch
          helperText="Check this option if you want this rubric's scores to start at zero."
          label="Start At Zero"
          name="startAtZero"
        />
      </Stack>
      <Callouts
        label=""
        ListRenderer={Criteria}
        name="children"
      />
    </>
  )
}

export default Content
