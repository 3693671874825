import HFNumberField from 'common/formControls/textInputs/HFNumberField'
import { rem } from 'styling/theming/base/mixins'
import InteractiveInstructions from './InteractiveInstructions'
import QuestionPrompt from './QuestionPrompt'
import StudentInstructions from './StudentInstructions'
import { featurePaneShape } from './utils'

const FeatureTalkBack = ({ disabled, feature }) => (
  <>

    <InteractiveInstructions
      disabled={disabled}
      feature={feature}
    />

    <QuestionPrompt
      disabled={disabled}
      feature={feature}
    />

    <StudentInstructions
      disabled={disabled}
      feature={feature}
    />

    <HFNumberField
      disabled={disabled}
      helperText="Min: 5; Max: 60 seconds"
      label="Maximum Length of Recordings"
      max={60}
      min={5}
      name={`${feature}-data.audioLengthLimit`}
      style={{ marginTop: rem(5) }}
    />

  </>
)

FeatureTalkBack.propTypes = featurePaneShape

export default FeatureTalkBack
