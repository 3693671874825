import PropTypes from 'prop-types'
import EchoRichTextEditor from './EchoRichTextEditor'
import { availableEchoFeatures } from './utils'

const InteractiveInstructions = ({ disabled, feature }) => (
  <EchoRichTextEditor
    disabled={disabled}
    label="Interactive Instructions"
    name={`${feature}-data.interactiveInstructions`}
    required={!disabled}
  />
)

InteractiveInstructions.propTypes = {
  disabled: PropTypes.bool.isRequired,
  feature: PropTypes.oneOf(availableEchoFeatures).isRequired,
}

export default InteractiveInstructions
