import { CONTENT_TYPE_BLOCK, CONTENT_TYPE_PAGE, CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import withContent from 'hoc/withContent'
import { formPropsShape } from 'hss/ContentBuilder/utils'
import HFRadioGroup from 'common/formControls/switches/HFRadioGroup'
import Headline from 'common/text/Headline'
import ChildListSection from '../ChildListSection'
import CurriculumForm from '../CurriculumForm'
import ImageControls from '../ImageControls'

const Page = formProps => (
  <CurriculumForm
    defaultProps={{ imageBleed: '' }}
    formProps={formProps}
    omitAssetCode
  >

    <ImageControls
      formProps={formProps}
      thumb={false}
    />
    <Headline
      mt={4}
      title="Allow Image Bleed"
    >
      <HFRadioGroup
        name="imageBleed"
        options={[
          { id: '', label: 'None', value: '', description: 'No bleeding, default padding applied to images' },
          { id: 'width', label: 'Width', value: 'width', description: 'Allow image bleeding to the left and right edges' },
          { id: 'height', label: 'Height', value: 'height', description: 'Allow image bleeding to the bottom edge' },
        ]}
      />
    </Headline>
    <ChildListSection
      contentTypes={[CONTENT_TYPE_BLOCK, CONTENT_TYPE_SCAFFOLD]}
      formProps={formProps}
      name="children"
      title="Content Blocks"
    />

  </CurriculumForm>
)

Page.propTypes = formPropsShape.isRequired

const PageWithContent = withContent(Page, {
  contentType: CONTENT_TYPE_PAGE,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default PageWithContent
