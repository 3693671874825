import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import CollapsibleSection, { StylerForSingleField } from 'common/layout/CollapsibleSection'
import { isEmptyString } from 'fp/strings'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'

const StudentInstructions = ({
  className,
  name = 'studentInstructions',
}) => {
  const studentInstructions = useWatch({ name })

  return (
    <CollapsibleSection
      className={className}
      empty={isEmptyString(studentInstructions)}
      label="Student Instructions"
    >
      <HFRichTextEdit
        aria-label="student instructions"
        label=""
        name={name}
      />
    </CollapsibleSection>
  )
}

StudentInstructions.propTypes = {
  name: PropTypes.string,
}

const StyledStudentInstructions = StylerForSingleField(StudentInstructions)

export default StyledStudentInstructions
