import MenuItem from '@mui/material/MenuItem'
import HFSelect from 'common/formControls/selects/HFSelect'
import withProps from 'hoc/withProps'

const Icon = withProps('span', {
  className: 'material-symbols-outlined',
  style: { paddingRight: 10 },
})

const WrapMode = () => (
  <HFSelect
    helperText="This controls how surrounding content behaves when there is enough screen real estate to support wrapping.  The default is to use the full available width and to not wrap"
    label="Content Placement"
    name="float"
  >
    <MenuItem value="left">
      <Icon>format_image_left</Icon> Left
    </MenuItem>
    <MenuItem value="none"><Icon>calendar_view_day</Icon> Normal (default)</MenuItem>
    <MenuItem value="right"><Icon>format_image_right</Icon> Right</MenuItem>
  </HFSelect>
)

export default WrapMode
