import HFTextField from 'common/formControls/textInputs/HFTextField'
import { CONTENT_TYPE_CHAPTER, CONTENT_TYPE_SECTION } from 'core/consts'
import withContent from 'hoc/withContent'
import ChapterSummary from '../ChapterSummary'
import ChildListSection from '../ChildListSection'
import CurriculumForm from '../CurriculumForm'
import ImageControls from '../ImageControls'
import PullQuote from './PullQuote'

const Chapter = formProps => (
  <CurriculumForm
    assetCodeRequired
    formProps={formProps}
    showVocabRollup
    tagsAllowed
  >
    <HFTextField
      name="subtitle"
      variant="filled"
    />

    <HFTextField
      label="Library Blurb"
      maxRows={4}
      multiline
      name="blurb"
      variant="filled"
    />

    <PullQuote />

    <ImageControls
      formProps={formProps}
      full={false}
    />

    <ChapterSummary formProps={formProps} />

    <ChildListSection
      contentTypes={[CONTENT_TYPE_SECTION]}
      formProps={formProps}
      name="children"
      title="Sections"
    />

  </CurriculumForm>
)

Chapter.propTypes = {}

const ChapterWithContent = withContent(Chapter, {
  contentType: CONTENT_TYPE_CHAPTER,
  forceRefresh: true,
  queryParams: { childDepth: 3 },
})

export default ChapterWithContent
