import { createElement } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'fp/objects'
import { contentShape, contentTypeShape } from 'core/shapes'
import { contentBuilderUrl } from 'routing/consts'
import { CONTENT_STATE_DRAFT } from 'core/consts'

export const withEdit = Component => () => createElement(Component, { editing: true })

export const pullFormValues = ({ content, contentType }) => ({
  assetCode: '',
  contentState: content?.contentState || CONTENT_STATE_DRAFT,
  contentType,
  ...omit('data')(content || {}),
  ...content?.data,
})

export const buildContentUrl = ({ contentType, id }) => `${contentBuilderUrl}/curriculum/${contentType}/${id}`

export const formProps = {
  content: contentShape,
  contentType: contentTypeShape.isRequired,
  editing: PropTypes.bool,
}

export const formPropsShape = PropTypes.shape(formProps)
