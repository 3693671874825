import { CONTENT_TYPE_BLOCK } from 'core/consts'
import withContent from 'hoc/withContent'
import BaseBlock from './BaseBlock'

const BlockWithContent = withContent(BaseBlock, {
  contentType: CONTENT_TYPE_BLOCK,
  forceRefresh: true,
  queryParams: { childDepth: 3 },
})

export default BlockWithContent
