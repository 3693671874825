import Button from '@mui/material/Button'
import { useFormContext, useWatch } from 'react-hook-form'
import { Plus } from 'react-feather'
import { AdvancedListPrime } from 'common/formControls/lists/AdvancedList'
import { CONTENT_TYPE_RUBRIC_CRITERIA } from 'core/consts'
import { arraySequence } from 'fp/arrays'
import { generateId, identity, takeSecond } from 'fp/utils'
import ListItemRenderer from './ListItemRenderer'

const Criteria = (props) => {
  const { getValues, setValue } = useFormContext()
  const [
    maxScore,
    startAtZero,
  ] = useWatch({ name: ['maxScore', 'startAtZero'] })

  const scoreAdder = startAtZero ? 0 : 1
  const adjustedMaxScore = maxScore + (startAtZero ? 1 : 0)

  const newOptions = () => arraySequence(adjustedMaxScore).map(i => ({ id: generateId(), description: '', score: i + scoreAdder }))

  const addItem = () => {
    // TODO: figure out why the built-in addItem of AdvancedListPrime
    // used a stale copy of children
    const newItem = {
      contentType: CONTENT_TYPE_RUBRIC_CRITERIA,
      data: {
        options: newOptions(),
        title: '',
      },
    }
    const children = getValues('children')
    setValue('children', [...children, newItem], { shouldDirty: true })
  }

  // TODO: Put sorting back in
  /*
    If have 6 ratings, and you change the maxScore to 3, then to 8,
    then drag the ratings around, you will see that AdvancedList
    is using stale values and descriptions disappear.
    If you change another form value, like startAtZero,
    before dragging items around, then AdvancedList gets updated values.
  */
  return (
    <AdvancedListPrime
      allowReordering={false}
      getter={identity}
      ItemRenderer={ListItemRenderer}
      setter={takeSecond}
      {...props}
    >
      {({ disabled }) => (
        <Button
          disabled={disabled}
          onClick={addItem}
          style={{ marginTop: '2rem', paddingLeft: 0 }}
          variant="tertiary"
        >
          <Plus />
          <span style={{ marginLeft: '1rem' }}>Add Rating</span>
        </Button>
      )}
    </AdvancedListPrime>
  )
}

export default Criteria
