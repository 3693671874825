import { CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET } from 'core/consts'
import withContent from 'hoc/withContent'
import { formPropsShape } from 'hss/ContentBuilder/utils'
import CurriculumForm from '../CurriculumForm'
import ChildListSection from '../ChildListSection'

const Pageset = formProps => (
  <CurriculumForm
    formProps={formProps}
    omitAssetCode
  >

    <ChildListSection
      contentTypes={[CONTENT_TYPE_PAGE]}
      formProps={formProps}
      name="children"
      title="Child pages"
    />

  </CurriculumForm>
)

Pageset.propTypes = formPropsShape.isRequired

const PagesetWithContent = withContent(Pageset, {
  contentType: CONTENT_TYPE_PAGESET,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default PagesetWithContent
