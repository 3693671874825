import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_ANIMATOR } from 'core/consts'
import AnimatorInteractive from 'hss/sections/contentBlocks/interactives/Animator'
import withContent from 'hoc/withContent'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_ANIMATOR
const Interactive = AnimatorInteractive
const defaultValues = {
  actions: '[]',
  debug: true,
  fps: 30,
  pauses: '[]',
  completionTarget: 80,
  src: '',
}

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const Animator = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    captionAllowed
    framelessAllowed={false}
    name="Interactive-Animator"
  >
    <Content />
  </Form>
)

const AnimatorWithContent = withContent(Animator, { contentType: CONTENT_TYPE_INTERACTIVE })

export default AnimatorWithContent
