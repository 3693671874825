import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import withContent from 'hoc/withContent'
import BaseBlock from '../Block/BaseBlock'

const BlockWithContent = withContent(BaseBlock, {
  contentType: CONTENT_TYPE_SCAFFOLD,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default BlockWithContent
