import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import { componentShape } from 'core/shapes'
import useToggleState from 'hooks/useToggleState'
import { builderContext } from '../builderContext'
import Preview from '.'

const PreviewButton = ({
  additionalInteractiveProps,
  children = 'Preview',
  Interactive,
  ...rest
}) => {
  const { currentValues, disabled, getTrigger } = useContext(builderContext)
  const [alertShown, setAlertShown] = useState(false)
  const [open, toggleOpen] = useToggleState(false)

  const onClick = async () => {
    /**
     * Something odd goes on here with hook-form and crossing context boundaries.
     * getTrigger returns a function that itself returns hook-form's trigger()
     * function, however it also seems to invoke it too, as what it actually
     * returns is a promise.
     * Originally I tried storing the trigger function itself in context but I was
     * seeing the same thing happen.
     *
     * It's really odd.
     *
     * At least in this format it sorta reads correctly as "getting the trigger",
     * however it does mean that we can't pass arguments to it and so it always
     * validates the entire form.  That's what I wanted anyhow so not really a big
     * deal.
     */
    const isValid = await getTrigger()

    if (!isValid) {
      setAlertShown(true)
      return
    }
    toggleOpen()
  }

  return (
    <>
      <Button {...{ color: 'secondary', ...rest, children, disabled, onClick, variant: 'secondary' }} />

      <Preview
        {...{
          additionalInteractiveProps,
          formValues: currentValues,
          Interactive,
          onClose: toggleOpen,
          open,
        }}
      />

      <Snackbar
        autoHideDuration={5000}
        onClose={() => setAlertShown(false)}
        open={alertShown}
      >
        <Alert severity="error">
          <strong>Problems with your inputs detected</strong>
          <br />
          please correct them and try again
        </Alert>
      </Snackbar>
    </>
  )
}

PreviewButton.propTypes = {
  additionalInteractiveProps: PropTypes.object,
  children: componentShape,
  Interactive: componentShape.isRequired,
}

export default PreviewButton
