import Button from '@mui/material/Button'
import { identity, takeSecond } from 'fp/utils'
import { AdvancedListPrime } from 'common/formControls/lists/AdvancedList'
import ListItemRenderer from './ListItemRenderer'

const Messages = props => (
  <AdvancedListPrime
    getter={identity}
    ItemRenderer={ListItemRenderer}
    name="messages"
    setter={takeSecond}
    {...props}
  >
    {({ addItem, disabled }) => (
      <Button
        disabled={disabled}
        onClick={() => addItem({
          coord: '20,20',
          message: '',
          animation: '',
        })}
      >
        add message
      </Button>
    )}
  </AdvancedListPrime>
)

export default Messages
