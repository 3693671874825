import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import { HFRichTextEditLite } from 'common/formControls/textInputs/HFRichTextEdit'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import HFTextField from 'common/formControls/textInputs/HFTextField'

const Cell = ({ itemIdx }) => {
  const { displayText } = useWatch({ name: `items.${itemIdx}` })
  return (
    <td>
      <HFSwitch
        label="Display only?"
        margin="dense"
        name={`items.${itemIdx}.displayText`}
      />
      {displayText
        ? (
          <HFRichTextEditLite
            minimized
            name={`items.${itemIdx}.value`}
            noLabel
          />
        )
        : (
          <HFTextField
            name={`items.${itemIdx}.value`}
            noLabel
          />
        )}
    </td>
  )
}
Cell.propTypes = {
  itemIdx: PropTypes.number.isRequired,
}

export default Cell
