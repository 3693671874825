import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { forwardRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { useFormContext, useWatch } from 'react-hook-form'
import Button from '@mui/material/Button'
import TextField from 'common/formControls/textInputs/TextField'
import { set } from 'fp/objects'
import { arraySequence, difference } from 'fp/arrays'
import { increment } from 'fp/numbers'
import Headline from 'common/text/Headline'
import Select from 'common/formControls/selects/Select'
import AdvancedList, { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import { identity, takeSecond } from 'fp/utils'

const isRequired = ({ value }) => value === 1

const markValues = arraySequence(10).map(increment)

const ItemRenderer = forwardRef(({
  disabled,
  index,
  onChange,
  removeItem,
  item,
  selected,
  value,
}, ref) => {
  const handleChange = fieldName => ({ target }) => onChange(set(fieldName, target.value)(value))
  const marks = useWatch({ name: 'marks' })
  const availableMarks = difference(markValues)(selected.map(mark => mark.value))
  const shouldDisableItemRemoval = isRequired(item) || marks.length < 3

  return (
    <Box
      alignItems="baseline"
      display="flex"
      mt={index === 0 ? 0 : 1}
      width="100%"
    >
      <Select
        disabled={isRequired(item)}
        fullWidth={false}
        label={`Mark ${index + 1}`}
        name={`mark-value-${index}`}
        onChange={handleChange('value')}
        style={{ width: 80, marginRight: 10 }}
        value={`${item.value}`}
      >
        {markValues.map(mark => (
          <MenuItem
            disabled={!availableMarks.includes(mark) && mark !== item.value}
            key={mark}
            value={mark}
          >
            {mark}
          </MenuItem>
        ))}
      </Select>
      <TextField
        InputProps={{ endAdornment: shouldDisableItemRemoval ? null : (
          <InputAdornment position="end">
            <IconButton
              disabled={disabled}
              onClick={() => removeItem(index)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </InputAdornment>
        ) }}
        key={item.value}
        label={`Label for Mark ${index + 1}`}
        name={`mark-label-${index}`}
        onChange={handleChange('label')}
        ref={ref}
        required={isRequired(item)}
        value={item.label}
      />
    </Box>
  )
})
ItemRenderer.propTypes = itemRendererProps

const Marks = (props) => {
  const marks = useWatch({ name: 'marks' })
  const { setValue } = useFormContext()

  useEffect(() => {
    const isSorted = marks.every(({ value }, i) => i === 0 || marks[i - 1].value <= value)
    if (!isSorted) {
      setValue('marks', [...marks].sort((a, b) => a.value - b.value))
    }
  }, [marks, setValue])

  return (
    <Headline
      hr
      mt={5}
      title="Mark Labels"
    >
      <AdvancedList
        getter={identity}
        setter={takeSecond}
        {...{ ItemRenderer, ...props, marks, allowReordering: false }}
      >
        {({ items, insertItem }) => {
          const availableMarks = difference(markValues)(items.map(mark => mark.value))
          return (
            <Button
              disabled={availableMarks.length === 0}
              onClick={() => { insertItem({ value: availableMarks[0], label: '' }, availableMarks[0] - 1) }}
              style={{ marginTop: '1.5rem' }}
            >
              Add
            </Button>
          )
        }}
      </AdvancedList>
    </Headline>
  )
}

export default Marks
