import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import { formPropsShape } from 'hss/ContentBuilder/utils'
import { getParentContentIsTabbed } from 'selectors/contentViewer'
import { componentShape } from 'core/shapes'
import Caption from 'hss/ContentBuilder/interactives/Form/Caption'
import ImageA11ySection from 'common/formControls/ImageA11ySection'
import { when } from 'fp/utils'

const ImageControls = (props) => {
  const {
    captionPosition,
    formProps: { content },
    full = true,
    label = 'Image',
    showMetaData = false,
    thumb = true,
  } = props
  const { contentType, id } = content || {}
  const tabbed = useSelector(getParentContentIsTabbed({ contentType, contentId: id }))
  const { setValue } = useFormContext()

  // if a captionPosition is passed in, then it can't be changed and doesn't need to be shown
  const showCaptionPosition = !captionPosition

  useEffect(() => {
    when(captionPosition, setValue, 'captionPosition', captionPosition)
  }, [captionPosition, setValue])

  if (tabbed) {
    return null
  }

  return (
    <>
      {Boolean(full) && (
        <>
          <HFAssetUploader
            label={label}
            name="uploadsMap.image"
            uploadType="image"
            variantLinkage={
              thumb
                ? [{
                  formField: 'uploadsMap.imageThumb',
                  variant: 'thumb',
                }]
                : null
            }
          />
          {Boolean(showMetaData) && (
            <>
              <ImageA11ySection altTextProps={{ required: false }} />
              <Caption showCaptionPosition={showCaptionPosition} />
            </>
          )}
        </>
      )}
      {Boolean(thumb) && (
        <HFAssetUploader
          label="Thumbnail"
          name="uploadsMap.imageThumb"
          uploadType="image"
          uploadVariant="thumb"
        />
      )}
    </>
  )
}

ImageControls.propTypes = {
  captionPosition: PropTypes.string,
  formProps: formPropsShape.isRequired,
  label: componentShape,
  full: PropTypes.bool,
  showMetaData: PropTypes.bool,
  thumb: PropTypes.bool,
}

export default ImageControls
