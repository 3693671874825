import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AvatarGroup from '@mui/material/AvatarGroup'
import * as consts from 'core/consts'
import { contentShape } from 'core/shapes'
import { imgSrc } from 'common/formControls/selects/TerritoriesPicker'
import { stateName } from './Territories/Output'

const ChildListTerritorialTags = (props) => {
  const { content: { territorySelections, territorySelectionType } } = props

  if (territorySelectionType === consts.TERRITORY_SELECTION_TYPE_EVERYONE) {
    return null
  }

  return (
    <Stack
      alignItems="center"
      direction="row"
    >
      <Typography
        mr={2}
        pt={0.5}
        variant="small-semibold"
      >
        {territorySelectionType === consts.TERRITORY_SELECTION_TYPE_INCLUDE
          ? <>Included:</>
          : <>Excluded:</>}
      </Typography>

      <Stack
        alignItems="center"
        direction="row"
        gap={-1}
        mt={1}
      >

        <AvatarGroup
          max={6}
          variant="small"
        >

          {territorySelections
            .sort()
            .map(id => (
              <Tooltip
                key={id}
                title={stateName(id)}
              >
                <Avatar
                  alt={stateName(id)}
                  src={imgSrc({ id })}
                  sx={{ width: 24, height: 24 }}
                  variant="shadow-1"
                />
              </Tooltip>
            ))}

        </AvatarGroup>

      </Stack>
    </Stack>
  )
}

ChildListTerritorialTags.propTypes = {
  content: contentShape.isRequired,
}

export default ChildListTerritorialTags
