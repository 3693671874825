import { useFormContext, useWatch } from 'react-hook-form'
import VennDiagramContent from 'hss/sections/contentBlocks/interactives/VennDiagram/Content'
import Headline from 'common/text/Headline'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import SampleAnswers from './SampleAnswers'

const Content = (props) => {
  const formValues = useWatch()
  const { setValue } = useFormContext()
  const updateParentForm = (key, value) => {
    setValue(key, value, { shouldDirty: true })
  }
  return (
    <Headline
      my={4}
      title="Venn Diagram"
    >
      <HFSwitch
        helperText="Display only, do not allow editing"
        name="readOnly"
      />
      <VennDiagramContent
        {...formValues}
        onChange={updateParentForm}
        readOnly={false}
      />
      <SampleAnswers {...props} />
    </Headline>
  )
}

export default Content
