import Box from '@mui/material/Box'
import { listShape } from 'common/formControls/lists/BasicList'
import withList from 'common/formControls/lists/withList'

const Callouts = ({ ListRenderer, index, ItemRenderer, ...rest }) => (
  <>
    <Box mt={2}>Rating Descriptions</Box>
    <ListRenderer {...rest} />
  </>
)

Callouts.propTypes = listShape

const WiredCallouts = withList(Callouts)

export default WiredCallouts
