import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_STACKED_SEQUENCE } from 'core/consts'
import Item from 'hss/sections/contentBlocks/interactives/StackedSequence/Item'
import StackedSequenceInteractive from 'hss/sections/contentBlocks/interactives/StackedSequence'
import withContent from 'hoc/withContent'
import Headline from 'common/text/Headline'
import StackedSequenceList from 'hss/sections/contentBlocks/interactives/StackedSequence/StackedSequenceList'
import Form from './Form'
import PreviewButton from './Form/Preview/PreviewButton'
import QuestionPrompt from './Form/QuestionPrompt'

const contentSubType = INTERACTIVE_TYPE_STACKED_SEQUENCE
const Interactive = StackedSequenceInteractive
const defaultValues = {
  promptHtml: '',
  responseTemplate: {
    items: [{ id: 1, content: '', header: '' }, { id: 2, content: '', header: '' }, { id: 3, content: '', header: '' }],
  },
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const StackedSequence = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-StackedSequence"
    standardsAllowed
  >
    {/* TODO: should there be a different field for title, or just use name? using name for now. */}
    <QuestionPrompt
      name="promptHtml"
      required={false}
    />

    <Headline
      hr
      mt={2}
      title="Stacked Sequence options"
    >
      <StackedSequenceList
        ItemRenderer={Item}
        name="responseTemplate.items"
      />
    </Headline>

  </Form>
)

const StackedSequenceWithContent = withContent(StackedSequence, { contentType: CONTENT_TYPE_INTERACTIVE })

export default StackedSequenceWithContent
