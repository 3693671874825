import Box from '@mui/material/Box'
import { useContext } from 'react'
import { builderContext } from 'hss/ContentBuilder/interactives/Form/builderContext'

const AncillaryPortal = () => {
  const { ancillaryPortalRef } = useContext(builderContext)

  return (
    <Box
      ref={ancillaryPortalRef}
      sx={{
        position: 'absolute',
        left: -60,
        top: 90,
        maxWidth: 50,
      }}
    />
  )
}

export default AncillaryPortal
