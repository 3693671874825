import HFSwitch from 'common/formControls/switches/HFSwitch'
import {
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SECTION,
  SECTION_CONTENT_TYPES,
} from 'core/consts'
import { contentShape } from 'core/shapes'
import { dedupe } from 'fp/arrays'
import { get } from 'fp/objects'
import withContent from 'hoc/withContent'
import useParentContent from 'hooks/useParentContent'
import ChildListSection from '../ChildListSection'
import CurriculumForm from '../CurriculumForm'

const Section = (formProps) => {
  const { content } = formProps
  const parentContent = useParentContent(content)

  const isDBI = (parentContent?.contentType === CONTENT_TYPE_SECTION)
    || content.parent?.contentType === CONTENT_TYPE_SECTION

  const hasEchoSibling = dedupe(content
    .children
    .map(get('contentType')))
    .includes(CONTENT_TYPE_ECHO)

  const hasSectionSibling = dedupe(content
    .children
    .map(get('contentType')))
    .includes(CONTENT_TYPE_SECTION)

  return (
    <CurriculumForm
      formProps={formProps}
      isDBI={isDBI}
      omitAssetCode
      tagsAllowed
    >

      <HFSwitch
        disabled={isDBI || hasEchoSibling || hasSectionSibling}
        helperText="DBI (tabbed) sections have their subsections render as tabs within the content viewer."
        label="Render as Tabs"
        name="tabbed"
      />

      <ChildListSection
        contentTypes={SECTION_CONTENT_TYPES}
        formProps={formProps}
        isDBI={isDBI}
        name="children"
        title={hasEchoSibling ? 'Echo' : 'Subsections'}
      />
    </CurriculumForm>
  )
}

Section.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  content: contentShape.isRequired,
}

const SectionWithContent = withContent(Section, {
  contentType: CONTENT_TYPE_SECTION,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default SectionWithContent
