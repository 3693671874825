import { useWatch } from 'react-hook-form'
import { compose } from 'redux'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import Headline from 'common/text/Headline'
import { filter, map, toKeyedObject } from 'fp/arrays'
import { orderedSections } from 'hss/sections/contentBlocks/interactives/VennDiagram/config'
import { pick } from 'fp/objects'
import { curryRight, identity } from 'fp/utils'

// TODO: storing sample answers for now, don't know what to do with them yet
const SampleAnswers = () => {
  const [sections, circles] = useWatch({ name: ['sections', 'circles'] })

  const sectionDescriptions = compose(
    curryRight(toKeyedObject, 'circleIds'),
    filter(({ circleIds }) => circles === 3 ? identity : !circleIds.includes(3)),
    map(pick(['circleIds', 'description'])),
  )(orderedSections)

  return (
    <Headline
      mt={4}
      title="Sample Answers"
    >
      {sections.map(({ circleIds }, idx) => (
        <HFTextField
          key={circleIds}
          label={`Sample response for ${sectionDescriptions[circleIds]?.description}`}
          name={`sections.${idx}.sampleResponse`}
        />
      ))}

    </Headline>
  )
}

export default SampleAnswers
