import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import { DEFAULT_RTE_FEATURES } from 'common/formControls/textInputs/RichTextEdit/DraftWrapper'
import withProps from 'hoc/withProps'

const EchoRichTextEditor = withProps(HFRichTextEdit, {
  features: {
    ...DEFAULT_RTE_FEATURES,
    'typography.forEcho': true,
  },
  maxHeight: '50vh',
})

export default EchoRichTextEditor
