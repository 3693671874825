import { CONTENT_TYPE_INTERACTIVE, CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET, INTERACTIVE_TYPE_TIMELINE } from 'core/consts'
import TimelineInteractive from 'hss/sections/contentBlocks/interactives/Timeline'
import withContent from 'hoc/withContent'
import CurriculumForm from 'hss/ContentBuilder/Curriculum/CurriculumForm'
import ChildListSection from 'hss/ContentBuilder/Curriculum/ChildListSection'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_TIMELINE
const Interactive = TimelineInteractive

const contentTypes = [CONTENT_TYPE_PAGESET, CONTENT_TYPE_PAGE]

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const Timeline = formProps => (
  <CurriculumForm
    {...{
      contentSubType,
      formProps,
      nameRequired: false,
      previewButton,
    }}
    assetCodeRequired
    captionAllowed
    changingContentStateAllowed={false}
    interactiveInstructionsAllowed
    studentInstructionsAllowed
    variants={{
      [interactiveVariants.NORMAL]: {
        displayName: 'Normal',
      },
      [interactiveVariants.TIMELINE_ECON]: {
        displayName: 'Econ',
      },
    }}
  >

    <Content />

    <ChildListSection
      contentTypes={contentTypes}
      formProps={formProps}
      name="children"
      title="Pages"
    />

  </CurriculumForm>
)

const TimelineWithContent = withContent(
  Timeline,
  { contentType: CONTENT_TYPE_INTERACTIVE,
    queryParams: { childDepth: 3 } },
)

export default TimelineWithContent
