import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_DRAW_TOOL } from 'core/consts'
import DrawToolInteractive from 'hss/sections/contentBlocks/interactives/DrawTool'
import withContent from 'hoc/withContent'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_DRAW_TOOL
const Interactive = DrawToolInteractive

const defaultValues = {
  canvasHeight: 450,
  canvasWidth: 600,
  dimension: 'horizontal',
  distance: 0,
  unit: 'px',
  uploadsMap: {},
}

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const DrawTool = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    captionAllowed
    name="Interactive-DrawTool"
  >
    <Content />
  </Form>
)

const DrawToolWithContent = withContent(DrawTool, { contentType: CONTENT_TYPE_INTERACTIVE })

export default DrawToolWithContent
