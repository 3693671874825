import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { contentShape, contentTypeShape } from 'core/shapes'
import { omit } from 'fp/objects'
import DualHeadline from 'common/text/DualHeadline'
import { getParentContentIsTabbed } from 'selectors/contentViewer'
import { CONTENT_TYPE_RUBRIC, CONTENT_TYPE_VOCABULARY } from 'core/consts'
import { builderContentSubTypesShape, labels } from '../../consts'

const ContentHeadline = (props) => {
  // istanbul ignore next line
  const {
    content = { data: {} },
    contentSubType,
    contentType,
    editing = false,
    isDBI = false,
    ...rest
  } = props
  const { assetCode, id, name } = content
  const isRubric = contentType === CONTENT_TYPE_RUBRIC
  const isVocab = contentType === CONTENT_TYPE_VOCABULARY
  const tabbed = useSelector(getParentContentIsTabbed({ contentType, contentId: id }))

  const title1 = useMemo(() => {
    const result = editing ? 'Editing' : 'Create'

    // istanbul ignore next line
    if (isDBI) return `${result} DBI Section:`

    // istanbul ignore next line
    if (tabbed) return `${result} Tab:`

    if (isRubric) return `${result} ${editing ? '' : 'New'} ${editing ? `'${ labels[CONTENT_TYPE_RUBRIC] }'` : ''}`

    if (isVocab) return `${result} ${editing ? '' : 'New'} ${labels[CONTENT_TYPE_VOCABULARY]}${editing ? ':' : ''}`

    if (!contentSubType) return `${result} ${labels[contentType]}:`

    // istanbul ignore next line
    const contentSubTypeName = labels[contentSubType] || ''

    return editing
      ? `${result} '${contentSubTypeName}'`
      : `${result} new`
  }, [contentSubType, contentType, editing, isDBI, isRubric, isVocab, tabbed])

  const title2 = useMemo(() => {
    if (isVocab) return `${name || ''}`

    if (contentType !== CONTENT_TYPE_RUBRIC && !contentSubType) return `${name || assetCode || id}`

    // istanbul ignore next line
    const contentSubTypeName = isRubric
      ? labels[CONTENT_TYPE_RUBRIC]
      : labels[contentSubType] || ''

    return editing
      ? assetCode ? ` - ${assetCode}` : ''
      : `'${contentSubTypeName}'`
  }, [assetCode, contentSubType, contentType, editing, id, isRubric, isVocab, name])

  return (
    <DualHeadline
      contained
      mb={4}
      size={2}
      swatch
      title1={title1}
      title2={title2}
      {...omit(['contentType', 'staticContext'])(rest)}
    />
  )
}

ContentHeadline.propTypes = {
  content: contentShape,
  contentSubType: builderContentSubTypesShape,
  contentType: contentTypeShape.isRequired,
  editing: PropTypes.bool,
  isDBI: PropTypes.bool,
}

export default ContentHeadline
