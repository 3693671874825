import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import HookFormSelect from 'common/formControls/selects/HFSelect'
import { isDefined } from 'fp/utils'
import Select from 'common/formControls/selects/Select'
import { supportedBlockVariants } from 'hss/ContentViewer/Chapter/Block/Wrapper'

const BlockVariantPicker = (props) => {
  const { onChange, value } = props

  const isControlled = isDefined(onChange)

  const Control = isControlled
    ? Select
    : HookFormSelect

  return (
    <Control
      label="Variant"
      name="variant"
      renderValue={(selected) => {
        const item = supportedBlockVariants.find(({ value: itemValue }) => itemValue === selected)
        return item ? item.label : ''
      }}
      {...isControlled
        ? {
          onChange,
          value,
        }
        : null
      }
    >
      {supportedBlockVariants.map(({ value: itemValue, label, title }) => (
        <MenuItem
          key={label}
          sx={{ display: 'block', maxWidth: 500, whiteSpace: 'normal' }}
          value={itemValue}
        >
          <Typography
            className="detailed-menuitem-label"
            variant="nav-item-semibold"
          >
            {label}
          </Typography>
          <Typography className="detailed-menuitem-title">{title}</Typography>
        </MenuItem>
      ))}
    </Control>
  )
}

BlockVariantPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default BlockVariantPicker
