import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import MuiChip from '@mui/material/Chip'
import { useSelector } from 'react-redux'
import { LEVELED_TEXT_BODY_FIELD_NAME, SPANISH_BODY_FIELD_NAME } from 'core/consts'
import { contentShape } from 'core/shapes'
import withProps from 'hoc/withProps'
import { hasAlternateBodiesForSelfOrDescendants } from 'selectors/content'

const Chip = withProps(MuiChip, { size: 'small', variant: 'contained', style: { marginRight: 5 } })

const TooltipChip = ({ label, title }) => (
  <Tooltip
    placement="bottom"
    title={title}
  >
    <span>
      <Chip
        label={label}
        variant="outlined"
      />
    </span>
  </Tooltip>
)
TooltipChip.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const ChildListTags = ({ content }) => {
  const hasLeveledText = useSelector(hasAlternateBodiesForSelfOrDescendants({
    kind: LEVELED_TEXT_BODY_FIELD_NAME,
    content,
  }))
  const hasSpanishText = useSelector(hasAlternateBodiesForSelfOrDescendants({
    kind: SPANISH_BODY_FIELD_NAME,
    content,
  }))

  return (hasLeveledText || hasSpanishText)
    ? (
      <div style={{ paddingTop: 3, paddingBottom: 6 }}>

        {!!hasSpanishText && (
          <TooltipChip
            label="ES"
            title="Item or descendant(s) contain(s) spanish texts"
          />
        )}

        {!!hasLeveledText && (
          <TooltipChip
            label="LVL"
            title="Item or descendant(s) contain(s) leveled texts"
          />
        )}

      </div>
    )
    : null
}

ChildListTags.propTypes = {
  content: contentShape.isRequired,
}

export default ChildListTags
