import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { compose } from 'redux'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import withHookForm from 'common/formControls/Form/withHookForm'
import { getListedContent } from 'selectors/content'
import { CONTENT_TYPE_RUBRIC } from 'core/consts'
import Autocomplete from 'common/formControls/selects/Autocomplete'
import { fallbackTo, isDefined, matches, noop, takeSecond, toggleBetween } from 'fp/utils'
import { filter, findObj, orderBy } from 'fp/arrays'
import { get, isObject } from 'fp/objects'
import Html from 'common/text/Html'

const RubricOption = ({ key, option, selected, ...rest }) => {
  const descriptionText = get('data.description')(option)

  const maxScoreText = compose(
    maxScore => toggleBetween(isDefined(maxScore), `Max Score: ${maxScore}`, undefined),
    get('data.maxScore'),
  )(option)

  const ratingText = compose(
    ratingsList => toggleBetween(ratingsList.length > 0, `Ratings: ${ratingsList.length}`, undefined),
    filter(matches('contentType', 'rubric-criteria')),
    get('children', { fallback: [] }),
  )(option)

  const metadataText = compose(
    fallbackTo(undefined),
    fallbackTo(ratingText),
    fallbackTo(maxScoreText),
    (scoreText, rtgText) => (isDefined(scoreText) && isDefined(rtgText))
      ? `${scoreText}, ${rtgText}`
      : undefined,
  )(maxScoreText, ratingText)

  return (
    <MenuItem
      key={key}
      {...rest}
      selected={selected}
      sx={{
        whiteSpace: 'normal',
        '&.MuiAutocomplete-option': {
          flexDirection: 'column',
          alignItems: 'flex-start',
          py: 2,
          px: 3,
        },
      }}
      value={option.id}
    >

      <Typography variant="nav-item-semibold">
        {option.name}
      </Typography>

      {Boolean(metadataText) && (
        <Typography component="div">
          {metadataText}
        </Typography>
      )}

      {Boolean(descriptionText) && (
        <Typography component="div">
          <Html body={descriptionText} />
        </Typography>
      )}

    </MenuItem>
  )
}

RubricOption.propTypes = {
  key: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  selected: PropTypes.bool,
}

const RubricPicker = ({ onChange, value: hfValue, ...rest }) => {
  const rubrics = useSelector(getListedContent({ contentType: CONTENT_TYPE_RUBRIC }))
  const [selectedRubric, setSelectedRubric] = useState(null)

  const sortedRubrics = useMemo(
    () => compose(
      orderBy('name'),
      fallbackTo([]),
    )(rubrics),
    [rubrics],
  )

  useEffect(() => {
    compose(
      setSelectedRubric,
      fallbackTo({ name: '' }),
      findObj('id', hfValue),
    )(rubrics)
  }, [rubrics, hfValue])

  const handleChange = compose(
    (onChange || noop),
    value => ({ target: { value } }),
    get('id'),
    takeSecond,
  )

  return (
    <Autocomplete
      disableClearable
      getOptionLabel={option => toggleBetween(isObject(option), option.name, null)}
      isOptionEqualToValue={(option, value) => option.id === value.id || value?.id === undefined}
      options={sortedRubrics}
      renderOption={({ key, ...restOptionProps }, option, { selected }) => (
        <RubricOption
          key={key}
          option={option}
          selected={selected}
          {...restOptionProps}
        />
      )}
      {...rest}
      onChange={handleChange}
      value={selectedRubric}
    />
  )
}

RubricPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}

const HFRubricComplete = withHookForm(RubricPicker)

export default HFRubricComplete
