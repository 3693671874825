import Box from '@mui/material/Box'
import { forwardRef, useId } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import FormLabel from '@mui/material/FormLabel'
import { set } from 'fp/objects'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import Checkbox from 'common/formControls/switches/Checkbox'
import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'

const excludeToolButtons = ['Format', 'Highlight', 'Indentation', 'Link', 'Lists']

const AnswerChoiceItemRenderer = forwardRef(({
  allowDeletion,
  disabled,
  index,
  onChange,
  removeItem,
  value,
}, ref) => {
  const handleValueChange = fieldName => ({ target }) => onChange(set(fieldName, target.value)(value))
  const handleCheckboxChange = fieldName => ({ target }) => onChange(set(fieldName, target.checked)(value))
  const handleRemove = () => removeItem(index)

  const answerNumber = index + 1

  const textLabelId = useId()
  const explanationLabelId = useId()

  return (
    <>
      <Box
        component="fieldset"
        flex={1}
      >
        <Box
          component="legend"
          mb={2}
        >
          {`Answer ${answerNumber}`}
        </Box>
        {/* TODO: Make RichTextEdit support a label prop, like TextField. */}
        <FormLabel id={textLabelId}>Text</FormLabel>
        <RichTextEdit
          {...{ disabled, excludeToolButtons, ref }}
          ariaLabelledBy={textLabelId}
          minimized
          onChange={handleValueChange('label')}
          required
          value={value.label}
          variant="outlined"
        />
        <Box my={2}>
          <Checkbox
            checked={Boolean(value.isCorrect)}
            label="Correct"
            name={`answer-${answerNumber}-is-correct`}
            onChange={handleCheckboxChange('isCorrect')}
          />
        </Box>
        <FormLabel id={explanationLabelId}>Explanation</FormLabel>
        <RichTextEdit
          {...{ disabled, excludeToolButtons }}
          ariaLabelledBy={explanationLabelId}
          minimized
          onChange={handleValueChange('explanation')}
          value={value.explanation}
          variant="outlined"
        />
      </Box>
      {Boolean(allowDeletion) && (
        <IconButton
          disabled={disabled}
          onClick={handleRemove}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </>
  )
})

AnswerChoiceItemRenderer.propTypes = itemRendererProps

export default AnswerChoiceItemRenderer
