import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import HFTextArea from 'common/formControls/textInputs/HFTextArea'
import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import Callouts from '../ImageTitleDrag/Callouts'
import Messages from './Messages'
import MessageRenderer from './MessageRenderer'

const Content = () => (
  <>
    <ContainedFormContent>
      <HFTextArea
        label="Image Description"
        name="imageAltText"
        required
      />

      <HFAssetUploader
        label="Backdrop Image"
        name="uploadsMap.backdrop"
        required
        uploadType="image"
      />

    </ContainedFormContent>

    <Callouts
      dropTargets={['MESSAGE']}
      ItemRenderer={MessageRenderer}
      ListRenderer={Messages}
      name="messages"
    />
  </>
)

export default Content
