import PropTypes from 'prop-types'
import EchoRichTextEditor from './EchoRichTextEditor'
import { availableEchoFeatures } from './utils'

const StudentInstructions = ({ disabled, feature }) => (
  <EchoRichTextEditor
    disabled={disabled}
    label="Student Instructions"
    minimized
    name={`${feature}-data.studentInstructions`}
  />
)

StudentInstructions.propTypes = {
  disabled: PropTypes.bool.isRequired,
  feature: PropTypes.oneOf(availableEchoFeatures).isRequired,
}

export default StudentInstructions
