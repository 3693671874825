import { useWatch } from 'react-hook-form'
import { useEffect, useState } from 'react'
import Switch from 'common/formControls/switches/HFSwitch'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import { generateId } from 'fp/utils'
import QuestionPrompt from '../Form/QuestionPrompt'
import Table from './Table'

const { NORMAL } = interactiveVariants

const Content = () => {
  const variant = useWatch({ name: 'variant' })
  const [promptContentKey, setPromptContentKey] = useState()

  useEffect(
    () => {
      // The RTE for the "prompt" field won't update unless we also update its contentKey.
      setPromptContentKey(generateId())
    },
    [variant],
  )

  return (
    <>
      <QuestionPrompt
        contentKey={promptContentKey}
        labelProps={{
          headline: true,
          mb: 2,
          mt: 3,
        }}
        name="prompt"
        required={false}
      />

      {Boolean(!variant || variant === NORMAL) && (
        <>
          <Table
            allowColumnEdits
            allowRowEdits
          />

          <Switch
            label="Group all items together?"
            name="groupItemsTogether"
          />
        </>
      )}

    </>
  )
}

export default Content
