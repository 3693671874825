import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import { useFormState, useWatch } from 'react-hook-form'
import { HFRichTextEditLite } from 'common/formControls/textInputs/HFRichTextEdit'
import Select from 'common/formControls/selects/HFSelect'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import { isEmptyString } from 'fp/strings'
import { isDefined } from 'fp/utils'

const Caption = (props) => {
  const {
    captionOnSideAllowed = true,
    required = false,
    showCaptionPosition = true,
  } = props
  const caption = useWatch({ name: 'caption' })
  const { errors } = useFormState({ name: 'caption' })

  const allowedCaptionPositions = showCaptionPosition
    ? captionOnSideAllowed
      ? [
        { value: 'left', name: 'Left' },
        { value: 'right', name: 'Right (default)' },
        { value: 'top', name: 'Above' },
        { value: 'bottom', name: 'Below' },
      ]
      : [
        { value: 'top', name: 'Above' },
        { value: 'bottom', name: 'Below (default)' },
      ]
    : []

  return (
    <CollapsibleSection
      empty={isEmptyString(caption)}
      hasErrors={isDefined(errors.caption)}
      label="Caption"
    >

      <HFRichTextEditLite
        aria-label="caption"
        label=""
        name="caption"
        required={required}
      />

      {Boolean(allowedCaptionPositions.length) && (
        <Select name="captionPosition">
          {allowedCaptionPositions.map(({ value, name }) => (
            <MenuItem
              key={value}
              value={value}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      )}
    </CollapsibleSection>
  )
}

Caption.propTypes = {
  captionOnSideAllowed: PropTypes.bool,
  required: PropTypes.bool,
  showCaptionPosition: PropTypes.bool,
}

export default Caption
