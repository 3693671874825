import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { CONTENT_TYPE_RUBRIC, CONTENT_TYPE_RUBRIC_CRITERIA } from 'core/consts'
import RubricInteractive from 'hss/sections/contentBlocks/interactives/Rubric'
import withContent from 'hoc/withContent'
import { getContentByIds } from 'selectors/content'
import { get } from 'fp/objects'
import { generateId } from 'fp/utils'
import { arraySequence } from 'fp/arrays'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const Interactive = RubricInteractive
const maxScore = 6
const defaultValues = {
  description: '',
  children: [{
    contentType: CONTENT_TYPE_RUBRIC_CRITERIA,
    data: {
      options: arraySequence(maxScore).map(i => ({ id: generateId(), description: '', score: i + 1 })),
      title: '',
    },
  }],
  maxScore,
  startAtZero: false,
}

const previewButton = props => (
  <PreviewButton {...{ Interactive, ...props }} />
)

const Rubric = (formProps) => {
  const { content } = formProps
  const children = content?.children
  const rubricChildren = useSelector(getContentByIds({ contentIds: children ? children.map(get('id')) : [] }))

  const formPropsWithChildren = content && rubricChildren
    ? produce(formProps, (draft) => {
      draft.content.children = rubricChildren
    })
    : formProps

  return (
    <Form
      {...{
        assetCodeRequired: false,
        defaultValues,
        formProps: formPropsWithChildren,
        interactiveInstructionsAllowed: false,
        nameRequired: true,
        omitAssetCode: true,
        previewButton,
        studentInstructionsAllowed: false,
      }}
      name="Interactive-Rubric"
      standardsAllowed
    >
      <Content />
    </Form>
  )
}

const RubricWithContent = withContent(Rubric, { contentType: CONTENT_TYPE_RUBRIC })

export default RubricWithContent
