import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import { useContext, useEffect } from 'react'
import TabList from 'common/layout/TabList'
import TE from 'common/indicators/TE'
import { rem } from 'styling/theming/base/mixins'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import { contentTypeShape } from 'core/shapes'
import { context as themeContext } from 'styling/theming/ThemeProvider'

const Tabs = (props) => {
  const {
    contentType,
    currentContentType,
    hasChapterSummaryAncestor,
    hasTeAncestor,
    setCurrentContentType,
    setCurrentTab,
    split,
  } = props

  const { setSuppressNextDirtyNavigationWarning } = useContext(additionalContext)
  const { setAccentColor } = useContext(themeContext) || {}

  const wide = useContainerQuery().up('md')

  useEffect(() => {
    setAccentColor(currentContentType === 'te' ? 'tangerine' : 'cobalt')
  }, [currentContentType, setAccentColor])

  const handleContentTypeChange = (e, newValue) => {
    if (newValue) {
      setSuppressNextDirtyNavigationWarning(true)
      setTimeout(() => setCurrentContentType(newValue), 100)
    }
  }

  const handleTabChange = (e, newValue) => {
    setSuppressNextDirtyNavigationWarning(true)
    setTimeout(() => setCurrentTab(newValue), 100)
  }

  return (
    <Grid container>
      <Grid
        item
        xs={wide ? 6 : 10}
      >
        <TabList
          aria-label="content and attributes"
          minSize="xs"
          onChange={handleTabChange}
        >
          <Tab
            label={currentContentType === 'te' ? <span><TE /> Content</span> : 'Content'}
            style={{ maxHeight: 63 }}
            value="#content"
          />

          <Tab
            label="Attributes"
            value="#attributes"
          />

          {!split && (
            <Tab
              label="Preview"
              value="#preview"
            />
          )}
        </TabList>
      </Grid>

      {Boolean(!hasTeAncestor
        && (contentType !== CONTENT_TYPE_SCAFFOLD)
        && (!hasChapterSummaryAncestor)) && (
        <Grid
          item
          sx={{
            textAlign: 'right',
            paddingRight: wide ? rem(3) : 0,
            borderBottom: '1px solid',
            borderColor: 'grey.4',
          }}
          xs={wide ? 6 : 2}
        >
          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={handleContentTypeChange}
            value={currentContentType}
          >
            <ToggleButton value="se">{wide ? 'Student Edition' : 'SE'}</ToggleButton>
            <ToggleButton value="te"><TE />{Boolean(wide) && <>&nbsp;Teacher Edition</>}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
    </Grid>
  )
}

Tabs.propTypes = {
  contentType: contentTypeShape.isRequired,
  currentContentType: PropTypes.oneOf(['se', 'te']).isRequired,
  hasChapterSummaryAncestor: PropTypes.bool.isRequired,
  hasTeAncestor: PropTypes.bool.isRequired,
  setCurrentContentType: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  split: PropTypes.bool.isRequired,
}

export default Tabs
