import PropTypes from 'prop-types'
import { forwardRef, useContext } from 'react'
import Portal from '@mui/material/Portal'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import { useWatch } from 'react-hook-form'
import { builderContext } from 'hss/ContentBuilder/interactives/Form/builderContext'
import { getQueryParams } from 'selectors/routing'
import { BODY_FIELD_NAME, LEVELED_TEXT_BODY_FIELD_NAME, SPANISH_BODY_FIELD_NAME } from 'core/consts'

const currentBodyPropType = PropTypes.oneOf([
  BODY_FIELD_NAME,
  LEVELED_TEXT_BODY_FIELD_NAME,
  SPANISH_BODY_FIELD_NAME,
])

const buttons = [
  {
    children: <span>EN</span>,
    title: 'English text (at-level)',
    value: BODY_FIELD_NAME,
  },

  {
    children: <span>ES</span>,
    title: 'Spanish text',
    value: SPANISH_BODY_FIELD_NAME,
  },

  {
    children: <span>LVL</span>,
    title: 'Leveled text',
    value: LEVELED_TEXT_BODY_FIELD_NAME,
  },
]

const TooltipToggleButton = forwardRef(({ children, title, value, ...rest }, ref) => (
  <Tooltip
    placement="right"
    title={title}
  >
    <ToggleButton
      aria-label={title}
      ref={ref}
      value={value}
      {...rest}
    >
      {children}
    </ToggleButton>
  </Tooltip>
))
TooltipToggleButton.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  value: currentBodyPropType.isRequired,
}

const ContentTypeTabs = ({ editorTop, hasTeAncestor }) => {
  const {
    ancillaryPortalRef,
    currentBodyType,
    setCurrentBodyType,
  } = useContext(builderContext)

  const { type: currentContentType = 'se' } = useSelector(getQueryParams)

  const contentBlockVariant = useWatch({ name: 'variant' })

  const isTe = contentBlockVariant === 'chapter-recap'
    || currentContentType === 'te'
    || hasTeAncestor

  return isTe
    ? null
    : (
      <Portal container={ancillaryPortalRef.current}>

        <ToggleButtonGroup
          exclusive
          onChange={(_, newValue) => setCurrentBodyType(newValue)}
          orientation="vertical"
          style={{ marginTop: editorTop }}
          value={currentBodyType}
        >

          {buttons.map(({ children, title, value, value: key }) => (
            <TooltipToggleButton
              {...{
                children, key, title, value,
              }}
            />
          ))}

        </ToggleButtonGroup>

      </Portal>
    )
}

ContentTypeTabs.propTypes = {
  editorTop: PropTypes.number.isRequired,
  hasTeAncestor: PropTypes.bool.isRequired,
}

export default ContentTypeTabs
