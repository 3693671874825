import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useWatch } from 'react-hook-form'
import {
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import withContent from 'hoc/withContent'
import { formPropsShape } from 'hss/ContentBuilder/utils'
import { HFRichTextEditLite } from 'common/formControls/textInputs/HFRichTextEdit'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import CurriculumForm from '../CurriculumForm'
import ChildListSection from '../ChildListSection'
import ImageControls from '../ImageControls'
import PullQuote from '../Chapter/PullQuote'

const useIsMultiSectioned = () => {
  const children = useWatch({ name: 'children' })

  return children[0]?.contentType === CONTENT_TYPE_SUBSECTION
}

const OnlyForSingleSection = ({ children }) => {
  const isMultiSectioned = useIsMultiSectioned()

  return isMultiSectioned ? null : children
}
OnlyForSingleSection.propTypes = { children: PropTypes.node.isRequired }

const Information = () => {
  const children = useWatch({ name: 'children' })

  return children.length
    ? null
    : (
      <Typography
        component="p"
        sx={{
          mt: 2,
          lineHeight: 1.5,
        }}
        variant="footnote"
      >
        <strong>About Source Content:</strong>{' '}
        The first item you add determines how the source will be presented.
        If you add a block first, then the source will be a single page much like
        a subsection.
        If you add a subsection first then the source content will have multiple
        pages, and behave more like a section.<br />
        <br />
        This option to choose between the two goes away after the first item is
        added. To convert a source from a single page to multiple pages, or vice
        versa, you will need to remove all children first.
      </Typography>
    )
}

const ContentSection = ({ formProps }) => {
  const children = useWatch({ name: 'children' })
  const contentTypes = children.length
    ? [children[0].contentType]
    : [CONTENT_TYPE_BLOCK, CONTENT_TYPE_SUBSECTION]

  return (
    <ChildListSection
      contentTypes={contentTypes}
      formProps={formProps}
      name="children"
      title="Source Content"
    >
      <Information />
    </ChildListSection>
  )
}

ContentSection.propTypes = formPropsShape.isRequired

const Source = formProps => (
  <CurriculumForm
    assetCodeRequired
    formProps={formProps}
    tagsAllowed
  >

    <HFTextField
      label="Genre"
      name="genre"
    />

    <HFTextField name="creator" />
    <HFTextField name="creatorType" />
    <HFTextField name="assetDate" />
    <HFRichTextEditLite name="assetIntroduction" />
    <HFTextField name="assetBlurb" />
    <HFTextField
      name="pageCount"
      type="number"
    />
    <PullQuote />
    <HFRichTextEditLite name="bibliographicInfo" />

    <OnlyForSingleSection>
      <ImageControls
        formProps={formProps}
        label="Hero Image"
        showMetaData
        thumb={false}
      />
    </OnlyForSingleSection>

    <HFAssetUploader
      label="Thumbnail"
      name="uploadsMap.imageThumb"
      uploadType="image"
      uploadVariant="thumb"
    />

    <ContentSection formProps={formProps} />

  </CurriculumForm>
)

Source.propTypes = formPropsShape.isRequired

const SubsectionWithContent = withContent(Source, {
  contentType: CONTENT_TYPE_SOURCE,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default SubsectionWithContent
