import PropTypes, { arrayOf } from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import { useFormState } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import TextField from 'common/formControls/textInputs/TextField'
import { contentShape, contentTypeShape } from 'core/shapes'
import Dialog from 'common/dialogs/Dialog'
import { isEmptyString, prefix } from 'fp/strings'
import {
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_SUBTYPE_SKILLS_ROLLUP,
  CONTENT_SUBTYPE_STANDARDS_ROLLUP,
  CONTENT_SUBTYPE_UNIT_NORMAL,
  CONTENT_SUBTYPE_UNIT_SUPPLEMENTAL,
  CONTENT_SUBTYPE_VOCAB_ROLLUP,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
} from 'core/consts'
import TE from 'common/indicators/TE'
import HFAssetCodeField from 'common/formControls/textInputs/HFAssetCodeField'
import { get, omit, set } from 'fp/objects'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import useReduxCallback, { BUSY } from 'hooks/useReduxCallback'
import actionTypes from 'reducers/actionTypes'
import AppBusy from 'common/indicators/AppBusy'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import ProficienciesPicker from 'common/formControls/selects/ProficienciesPicker'
import { isBlock } from 'selectors/content'
import withProps from 'hoc/withProps'
import { dedupe, intersection, isEmpty } from 'fp/arrays'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import { when } from 'fp/utils'
import { displayConfig, labels, rollupContentSubTypeLabels, rollupContentSubTypes } from '../consts'
import BlockVariantPicker from './BlockVariantPicker'
import ScaffoldAssociationPicker from './ScaffoldAssociationPicker'
import UnitContentSubTypePicker from './UnitContentSubTypePicker'

const contentTypesThatRequireAssetCode = [
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SUBSECTION,
]

export const allowedContentTypesShape = PropTypes.arrayOf(PropTypes.oneOf([
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_SUBTYPE_SKILLS_ROLLUP,
  CONTENT_SUBTYPE_STANDARDS_ROLLUP,
  CONTENT_SUBTYPE_UNIT_NORMAL,
  CONTENT_SUBTYPE_UNIT_SUPPLEMENTAL,
  CONTENT_SUBTYPE_VOCAB_ROLLUP,
  null, // to allow for plain blocks
]))

const ChildListNewItem = (props) => {
  const {
    addItem,
    binaryMode = false,
    contentSubTypes,
    contentTypes,
    disabled = false,
    isDBI = false,
    parentContent,
    tabbed = false,
  } = props

  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [variant, setVariant] = useState('none')
  const [assetCode, setAssetCode] = useState('')
  const [proficiencyIds, setProficiencyIds] = useState([])
  const [standalone, setStandalone] = useState(false)
  const [hasUncommittedChild, setHasUncommittedChild] = useState(false)

  const { isDirty } = useFormState()
  const { forcedDirty, setForcedDirty } = useContext(additionalContext)

  const [contentType, setContentType] = useState()
  const [contentSubType, setContentSubType] = useState()
  const [teacherEdition, setTeacherEdition] = useState(false)

  const [errors, setErrors] = useState({})
  const [cannotSubmit, setCannotSubmit] = useState(false)

  const callbackSuccess = ({ newChildResponse }) => {
    addItem(newChildResponse)
    setOpen(false)
  }

  const callbackFailure = () => { setOpen(false) }

  const [dispatchAddChild, status] = useReduxCallback({
    actionType: actionTypes.CONTENT_CREATE_AND_ADD_CHILD,
    onError: callbackFailure,
    onSuccess: callbackSuccess,
  })

  useEffect(() => {
    when(!forcedDirty && hasUncommittedChild, setHasUncommittedChild, false)
  }, [forcedDirty, hasUncommittedChild])

  const AddButton = withProps(Button, {
    disabled: disabled || hasUncommittedChild,
    size: 'small',
    style: { marginTop: '1.5rem', marginRight: '1rem', textTransform: 'none' },
  })

  const siblingContentTypes = dedupe(parentContent
    .children
    .map(get('contentType')))
  const siblingContentSubTypes = dedupe(parentContent
    .children
    .map(get('contentSubType')))
  const hasEchoSibling = siblingContentTypes.includes(CONTENT_TYPE_ECHO)
  const hasSubsectionSibling = siblingContentTypes.includes(CONTENT_TYPE_SUBSECTION)

  const isUnit = contentType === CONTENT_TYPE_UNIT
  const nameRequired = !isBlock({ contentType }) && !isUnit

  const dlgFields = useMemo(() => ({
    name: {
      required: nameRequired,
      value: name,
    },
    ...contentTypesThatRequireAssetCode.includes(contentType)
      ? {
        assetCode: {
          required: true,
          value: assetCode,
        },
      }
      : null,
    ...contentType === CONTENT_TYPE_BLOCK
      ? {
        variant: {
          required: true,
          value: variant,
        },
      }
      : null,
    ...contentType === CONTENT_TYPE_SCAFFOLD
      ? {
        proficiencyIds: {
          required: true,
          value: proficiencyIds,
        },
        standalone: {
          required: true,
          value: standalone,
        },
      }
      : null,
  }), [assetCode, contentType, name, nameRequired, proficiencyIds, standalone, variant])

  useDeepCompareEffect(() => {
    const requiredFields = Object.values(dlgFields)
      .filter(get('required'))

    setCannotSubmit((!isEmpty(requiredFields) && requiredFields
      .map(get('value'))
      .some(isEmptyString))
        || !!Object.entries(errors).length)
  }, [dlgFields, errors])

  const handleAssetCodeChange = ({ value, error }) => {
    setAssetCode(value)
    if (typeof error === 'string') {
      setErrors(set('assetCode', error))
    } else {
      setErrors(omit('assetCode'))
    }
  }

  const handleAddClicked = (ct, te = false, cst = undefined) => {
    setContentType(ct)
    setContentSubType(ct === CONTENT_TYPE_UNIT ? CONTENT_SUBTYPE_UNIT_NORMAL : cst)
    setName(cst === CONTENT_SUBTYPE_CHAPTER_SUMMARY
      ? 'Chapter Summary'
      : '')
    setOpen(true)
    setTeacherEdition(te)
  }

  const handleCreate = () => {
    dispatchAddChild({
      assetCode,
      contentSubType,
      contentType,
      data: {
        ...contentType === CONTENT_TYPE_SECTION && contentTypes.includes(CONTENT_TYPE_SUBSECTION)
          ? { tabbed }
          : null,
        // section within a section (aka DBI) implies tabbed is true
        ...contentType === CONTENT_TYPE_SECTION && parentContent.contentType === CONTENT_TYPE_SECTION
          ? { tabbed: true } : null,
        ...contentType === CONTENT_TYPE_SCAFFOLD
          ? { standalone } : null,
        variant,
      },
      name,
      parentContent,
      ...isBlock
        ? { proficiencyIds }
        : null,
      suppressUpdate: isDirty,
      teacherEdition,
    })
    setHasUncommittedChild(true)
    setForcedDirty(true)
  }

  const handleAddRollup = (fields) => {
    dispatchAddChild({
      ...fields,
      contentType: CONTENT_TYPE_BLOCK,
      parentContent,
      suppressUpdate: isDirty,
    })
    setHasUncommittedChild(true)
    setForcedDirty(true)
  }

  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (!open) {
      setName('')
      setAssetCode('')
    }
  }, [open])

  const newDlgLabel = useMemo(() => {
    const label = labels[contentSubType || contentType]

    if (isDBI) return 'Tab'

    if (contentType === CONTENT_TYPE_SECTION && contentTypes.includes(CONTENT_TYPE_SUBSECTION)) return 'DBI Section'

    if (contentType === CONTENT_TYPE_ECHO) return label

    if (contentTypes.includes(CONTENT_TYPE_SECTION)) return prefix(teacherEdition ? 'TE ' : 'SE ')(label)

    return label
  }, [contentSubType, contentType, contentTypes, isDBI, teacherEdition])

  const nameFieldRef = useRef()
  useEffect(() => {
    if (open) {
      // Focus doesn't actually get set unless we use setTimeout,
      // perhaps because the dialog needs to animate.
      setTimeout(() => { nameFieldRef.current?.focus() })
    }
  }, [open])

  if (binaryMode && intersection(siblingContentTypes)(contentTypes).length) return null

  return (
    <>
      {binaryMode && contentSubTypes.length
        ? (
          <AddButton onClick={() => handleAddClicked(contentTypes[0], false, contentSubTypes[0])}>
            Create {labels[contentSubTypes[0]]}
          </AddButton>
        )

        : contentTypes.map((ct) => {
          const isEcho = ct === CONTENT_TYPE_ECHO
          const label = parentContent.contentType === CONTENT_TYPE_ECHO && ct === CONTENT_TYPE_SCAFFOLD
            ? `${labels[ct] } (for response)`
            : labels[ct]

          // cannot add echo to tabbed sections
          if (isEcho && tabbed) return null

          // Only one echo allowed per section
          if (isEcho && hasEchoSibling) return null

          return (
            <Fragment key={ct}>

              {/* can't mix-n-match echoes and subsections */}
              {!(
                (isEcho && hasSubsectionSibling)
                || (ct === CONTENT_TYPE_SUBSECTION && hasEchoSibling)
              ) && (
                <>
                  <AddButton
                    disabled={disabled || hasUncommittedChild || (isEcho && isDirty)}
                    onClick={() => handleAddClicked(ct)}
                  >
                    Add
                    {' '}

                    <>
                      {ct === CONTENT_TYPE_SECTION ? 'SE' : ''}
                      {' '}
                      {`${label}${tabbed ? ' Tab' : ''}`}
                    </>

                  </AddButton>

                  {Boolean(isEcho && isDirty) && (
                    <Typography
                      display="block"
                      variant="footnote"
                    >
                      Changes must be saved before an Echo can be added
                    </Typography>
                  )}
                </>
              )}

              {Boolean(ct === CONTENT_TYPE_SECTION) && (
                <AddButton onClick={() => handleAddClicked(ct, true)}>
                  Add&nbsp;<TE />&nbsp;{label}
                </AddButton>
              )}

              {/* cannot add dbi sections to other dbi section or tabbed sections */}
              {Boolean(ct === CONTENT_TYPE_SUBSECTION
                && !isDBI
                && !tabbed
                && !hasEchoSibling
                && parentContent.contentType !== CONTENT_TYPE_SOURCE) && (
                <AddButton onClick={() => handleAddClicked(CONTENT_TYPE_SECTION)}>
                  Add DBI Section
                </AddButton>
              )}

            </Fragment>
          )
        })}

      {Boolean(parentContent.contentType === CONTENT_TYPE_SUBSECTION && !parentContent.contentSubType) && (
        <>
          <br />
          {rollupContentSubTypes.map(rollupType => (
            <AddButton
              disabled={disabled
                || hasUncommittedChild
                || siblingContentSubTypes.includes(rollupType)}
              key={rollupType}
              onClick={() => handleAddRollup({ contentSubType: rollupType })}
            >
              Add {rollupContentSubTypeLabels[rollupType]} Rollup
            </AddButton>
          ))}
        </>
      )}

      <ErrorBoundary moduleName="ChildListNewItem-Dialog">
        <Dialog
          disableAutoFocus
          disableEscapeKeyDown
          maxWidth="md"
          onClose={handleClose}
          onOpen={() => {
            if (String(name).length) {
              setTimeout(() => { document.getElementById('asset-code-field')?.focus() }, 100)
            }
          }}
          open={open}
          showCloseButton
          title={`Create New ${newDlgLabel}`}
        >
          <DialogContent>

            <TextField
              fullWidth
              helperText={displayConfig[contentType]?.nameHelperText}
              label={`Name${ nameRequired ? '' : ' (optional)' }`}
              name="name"
              onChange={({ target }) => setName(target.value)}
              ref={nameFieldRef}
              required={nameRequired}
              value={name}
            />

            {contentType === CONTENT_TYPE_UNIT && (
              <UnitContentSubTypePicker
                onChange={({ value }) => setContentSubType(value)}
                value={contentSubType}
              />
            )}

            {Boolean(contentTypesThatRequireAssetCode.includes(contentType)) && (
              <HFAssetCodeField
                error={Boolean(errors.assetCode)}
                fullWidth
                helperText={errors.assetCode}
                id="asset-code-field"
                mandatory
                nonHookForm
                onChange={handleAssetCodeChange}
                required
                value={assetCode}
              />
            )}

            {contentType === CONTENT_TYPE_BLOCK && (
              <BlockVariantPicker
                onChange={({ target }) => setVariant(target.value)}
                value={variant}
              />
            )}

            {contentType === CONTENT_TYPE_SCAFFOLD && (
              <ProficienciesPicker
                onChange={setProficiencyIds}
                required
                value={proficiencyIds}
              />
            )}

            {Boolean(contentType === CONTENT_TYPE_SCAFFOLD && parentContent.contentType !== CONTENT_TYPE_ECHO) && (
              <ScaffoldAssociationPicker
                onChange={setStandalone}
                value={standalone}
              />
            )}
          </DialogContent>

          <DialogActions>

            <Button
              color="secondary"
              onClick={handleClose}
              variant="secondary"
            >
              Cancel
            </Button>

            <Button
              color="secondary"
              disabled={cannotSubmit}
              onClick={handleCreate}
              variant="primary"
            >
              Create
            </Button>

          </DialogActions>

          <AppBusy open={status === BUSY} />
        </Dialog>
      </ErrorBoundary>

      {!!hasUncommittedChild && (
        <Typography
          display="block"
          fontStyle="oblique"
          mt={1}
          variant="small"
        >
          Uncommitted changes must be saved before another item can be added
        </Typography>
      )}
    </>
  )
}

ChildListNewItem.propTypes = {
  addItem: PropTypes.func.isRequired,
  binaryMode: PropTypes.bool,
  contentSubTypes: allowedContentTypesShape.isRequired,
  contentTypes: arrayOf(contentTypeShape).isRequired,
  disabled: PropTypes.bool,
  isDBI: PropTypes.bool,
  parentContent: contentShape.isRequired,
  tabbed: PropTypes.bool,
}

export default ChildListNewItem
