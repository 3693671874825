import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import Checkbox from 'common/formControls/switches/HFCheckbox'
import HFCollapsedRTE from 'common/formControls/textInputs/HFCollapsedRTE'

const Content = () => (
  <>
    <HFAssetUploader
      label="Video"
      name="uploadsMap.video"
      uploadType="video"
      variantLinkage={
        [{
          formField: 'uploadsMap.cc',
          variant: 'cc',
        },
        {
          formField: 'uploadsMap.audioDescription',
          variant: 'audioDescription',
        },
        {
          formField: 'uploadsMap.image',
          variant: 'image',
        }]
      }
    />

    <HFAssetUploader
      label="Poster Image"
      name="uploadsMap.image"
      uploadType="image"
    />

    <HFAssetUploader
      label="Closed Captions"
      name="uploadsMap.cc"
      uploadType="vtt"
    />

    <HFAssetUploader
      label="Audio Description"
      name="uploadsMap.audioDescription"
      uploadType="vtt"
    />

    <HFCollapsedRTE
      label="Scene Description"
      name="sceneDescription"
    />

    <Checkbox name="allowAnnotations" />
  </>
)

export default Content
