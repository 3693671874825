import { forwardRef, useMemo } from 'react'
import { compose } from 'redux'
import {
  CONTENT_STATE_PUBLISHED,
  CONTENT_TYPE_SCAFFOLD,
  INTERACTIVE_TYPE_IMAGE,
  LEVELED_TEXT_BODY_FIELD_NAME,
  SPANISH_BODY_FIELD_NAME,
  TEACHER_EDITION_PREFIX,
} from 'core/consts'
import Form from 'common/formControls/Form'
import { formPropsShape, pullFormValues } from 'hss/ContentBuilder/utils'
import actionTypes from 'reducers/actionTypes'
import { componentShape } from 'core/shapes'
import { mapKeys, omit, set } from 'fp/objects'
import { prefix } from 'fp/strings'
import { isEmptyBlock } from 'projections/content'

const omitFromTE = [
  'assetCode',
  'children',
  'childrenCount',
  'contentSubType',
  'name',
  'parent',
  'scoring',
  'teacherEdition',
  'uploadsMap',
]

const defaultBlockFields = {
  [LEVELED_TEXT_BODY_FIELD_NAME]: '',
  [SPANISH_BODY_FIELD_NAME]: '',
  academicVocabPhraseIds: [],
  academicVocabPhrases: [],
  applicationStandardIds: [],
  applicationStandards: [],
  body: '',
  contentState: CONTENT_STATE_PUBLISHED,
  contentType: 'block',
  contentVocabPhraseIds: [],
  contentVocabPhrases: [],
  float: 'none',
  footnoteVocabPhraseIds: [],
  footnoteVocabPhrases: [],
  instructionStandardIds: [],
  instructionStandards: [],
  tagIds: [],
  tags: [],
}

const EditorContainer = forwardRef(({ children, formProps, ...rest }, ref) => {
  const defaultValues = useMemo(() => {
    const teValues = compose(
      mapKeys(prefix(TEACHER_EDITION_PREFIX)),
      omit(omitFromTE),
      () => ({
        ...pullFormValues({
          ...formProps,
          content: formProps.content?.teacherEditionContent || defaultBlockFields,
        }),
      }),
    )()

    const result = {
      ...defaultBlockFields,
      ...formProps.content?.contentType === CONTENT_TYPE_SCAFFOLD
        ? { proficiencyIds: [] }
        : null,
      ...pullFormValues(formProps),
      ...teValues,
    }

    const empty = isEmptyBlock(result.body)

    if (result.variant === 'chapter-recap' && empty) {
      return set('body', `
        <div
          data-variant="insert-interactive"
          data-subtypes='["${INTERACTIVE_TYPE_IMAGE}"]'
          data-label="Choose Optional Image"
        > </div>
        <h2 data-typography="content-heading">Heading</h2>
        <ol type="1">
          <li data-variant="unstyled">Item 1</li>
          <li data-variant="unstyled">Item 2</li>
        </ol>
      `)(result)
    }

    if (result.variant === 'research-link' && empty) {
      return set('body', `
        <h3 data-typography="sub-heading">Title</h3>
        <p>
          description
        </p>
      `)(result)
    }

    return result
  }, [formProps])

  return (
    <Form
      actionType={actionTypes.CONTENT_SAVE}
      defaultValues={defaultValues}
      name="EditorContainer"
      preventEnterSubmits
      ref={ref}
      suppressRequiredLabel
      variant="outlined"
      {...rest}
    >

      {children}

    </Form>
  )
})

EditorContainer.propTypes = {
  children: componentShape.isRequired,
  formProps: formPropsShape.isRequired,
}

export default EditorContainer
