import Box from '@mui/material/Box'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'

const Content = () => (
  <>
    <Box mb={2}>
      <HFAssetUploader
        label="Image"
        name="uploadsMap.image"
        required
        uploadType="image"
      />
    </Box>

    <HFTextField
      label="Image Alt Text"
      name="imageAltText"
      required
    />
  </>
)

export default Content
