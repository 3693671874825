import PropTypes from 'prop-types'
import { useFormContext, useWatch } from 'react-hook-form'
import { useEffect } from 'react'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import ImageA11ySection from 'common/formControls/ImageA11ySection'
import { wrapCantoDirective } from 'common/formControls/selects/AssetUploader/utils'

const Content = ({ setCaptionOnSideAllowed }) => {
  const [
    includeSwatch,
    noGutters,
    scrollHorizontally,
    sensitive,
  ] = useWatch({ name: [
    'includeSwatch',
    'noGutters',
    'scrollHorizontally',
    'sensitive',
  ] })

  const { setValue } = useFormContext()

  useEffect(() => {
    if (scrollHorizontally) {
      setValue('includeSwatch', false)
      setValue('noGutters', false)
    }
  }, [scrollHorizontally, setValue])

  useEffect(() => {
    if (includeSwatch) {
      setValue('noGutters', false)
    }
  }, [includeSwatch, setValue])

  useEffect(() => {
    if (scrollHorizontally || noGutters) {
      setValue('captionPosition', 'bottom')
      setCaptionOnSideAllowed(false)
    } else {
      setCaptionOnSideAllowed(true)
    }
  }, [noGutters, scrollHorizontally, setCaptionOnSideAllowed, setValue])

  const onCantoFields = (fields) => {
    Object.entries(fields).forEach(([key, value]) => setValue(
      key,
      wrapCantoDirective(value),
      { shouldDirty: true },
    ))
  }

  return (
    <>
      <HFAssetUploader
        label="Image"
        name="uploadsMap.image"
        onCantoFields={onCantoFields}
        required
        uploadType="image"
        variantLinkage={[{
          formField: 'uploadsMap.imageThumb',
          variant: 'thumb',
        }]}
      />

      <HFAssetUploader
        label="Thumbnail"
        name="uploadsMap.imageThumb"
        required
        uploadType="image"
        uploadVariant="thumb"
      />

      <HFSwitch
        helperText="Use for wide images."
        label="Scroll horizontally"
        name="scrollHorizontally"
      />
      <br />

      <HFSwitch
        disabled={Boolean(scrollHorizontally)}
        helperText="Displays a colored block over an edge of the image.  Color is determined by the containing subsection. (only applies when there are margins and a caption, and the caption is placed below the image)"
        label="Include accent color swatch"
        name="includeSwatch"
      />
      <br />

      <HFSwitch
        disabled={Boolean(scrollHorizontally || includeSwatch)}
        helperText="Allows image to span the full width of the page, similar to a hero image."
        label="Display full width"
        name="noGutters"
      />
      <br />

      <HFSwitch
        helperText="Initially hides the image and lets the user decide if they want to view it"
        label="Sensitive content"
        name="sensitive"
      />

      {Boolean(sensitive) && (
        <HFTextField
          label="Sensitive Content Warning"
          name="sensitiveWarning"
        />
      )}
      <br /><br />

      <ImageA11ySection />
    </>
  )
}

Content.propTypes = {
  setCaptionOnSideAllowed: PropTypes.func.isRequired,
}

export default Content
