import PropTypes from 'prop-types'
import { importantRem } from 'styling/theming/base/mixins'
import QuestionPromptPrime from 'hss/ContentBuilder/interactives/Form/QuestionPrompt'
import { availableEchoFeatures } from './utils'

const QuestionPrompt = ({ disabled, feature }) => (
  <QuestionPromptPrime
    disabled={disabled}
    name={`${feature}-data.prompt`}
    required={!disabled}
    sx={{ marginTop: importantRem(4) }}
  />
)

QuestionPrompt.propTypes = {
  disabled: PropTypes.bool.isRequired,
  feature: PropTypes.oneOf(availableEchoFeatures).isRequired,
}

export default QuestionPrompt
